import * as React from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Card,
  FormControl,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { useSelector } from "react-redux";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftTypography from "components/SoftTypography";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none", // "none" will hide checkboxes on rows
  selectableRowsHeader: false, // Set to false to hide the header checkbox
  elevation: 0,
};

const EmployeesList = () => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const { fetchError, fetchSuccess } = useSnackbar();
  const [assetTypes, setAssetTypes] = React.useState(null);
  const [assetTypeFilter, setAssetTypeFilter] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [error, setError] = React.useState(null);

  const handleSwap = async (userMail) => {
    const swapData = {
      actualEmail: user.email,
      actualRole: user.Role[0],
      actualRoleId: user.roleId,
      actualName: user.name,
      actualUserId: user.userId,
      userMail: userMail,
    };
    try {
      await axios.post("/auth/switch-user", swapData);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleStatus = async (data, isActive) => {
    try {
      await axios.put(`/employee/is-active/${data?.employee_id}`, {
        is_active: isActive,
      });
      fetchData();
      fetchSuccess("Status updated successfully");
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const employeeData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <span
              onClick={
                user?.roleId === 2 ? () => handleSwap(employeeData.email) : null
              }
              style={{
                cursor: "pointer",
              }}
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "average_rating",
      label: "Rating",
      options: {
        filter: false,
        customBodyRender: (value) => {
          const rating = value || 0;
          return <span>⭐ {rating} star</span>;
        },
      },
    },
    { name: "email", label: "Email" },
    { name: "asset_type_name", label: "Asset Type" },
    { name: "phone", label: "Phone" },
    {
      name: "skill_set",
      label: "Skills",
      options: {
        filter: false,
        customBodyRender: (value) => {
          const displayValue =
            value?.length > 20 ? `${value.substring(0, 20)}...` : value;
          return (
            <Tooltip title={value}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              onChange={(event) => handleStatus(data, event.target.checked)}
              color="primary"
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const employeeData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate(`/edit-employee/${employeeData.employee_id}`)
                }
              >
                <EditIcon />
              </IconButton>
              {user?.roleId === 2 && (
                <IconButton
                  aria-label="edit"
                  onClick={() => handleSwap(employeeData.email)}
                >
                  <SwapHorizIcon />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    },
  ];

  const fetchData = React.useCallback(async () => {
    try {
      const response = await axios.get(`/employee/roles/${4}`, {
        params: {
          filters: {
            asset_type_id: assetTypeFilter?.asset_type_id,
          },
        },
      });
      const { data } = response;

      setRows(data?.list);
    } catch (error) {
      setError(error);
    }
  }, [assetTypeFilter]);

  React.useEffect(() => {
    fetchData();
  }, [assetTypeFilter, fetchData]);

  const fetchAssets = async () => {
    try {
      const assetTypesData = await axios.get("/asset-types");
      setAssetTypes(assetTypesData?.data?.list);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  React.useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div
          style={{
            display: "block",
          }}
        >
          <Grid>
            <FormControl sx={{ paddingLeft: "10px", marginBottom: "2px" }}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                sx={{ paddingLeft: "10px" }}
              >
                Asset Types
              </SoftTypography>
              <Autocomplete
                options={assetTypes || []}
                getOptionLabel={(option) => option.asset_type_name}
                value={assetTypeFilter}
                onChange={(event, value) => {
                  setAssetTypeFilter(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </FormControl>
            <SoftButton
              variant="gradient"
              color="info"
              onClick={() => navigate(`/add-employee`)}
              sx={{ float: "right" }}
            >
              Add Employee
            </SoftButton>
          </Grid>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-employees"
              title={"Manage Employees"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          {error && <div>Error: {error.message}</div>}
        </SoftBox>
      </Card>
    </DashboardLayout>
  );
};

export default EmployeesList;
