import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import axios from "../../api";
import {
  TextField,
  FormControl,
  Grid,
  Box,
  Modal,
  Autocomplete,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SoftTypography from "components/SoftTypography";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftButton from "components/SoftButton";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461", // Lighter black color
    borderRadius: "8px", // Curved border
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666", // Lighter black color on hover
  },
};

const todayDate = new Date().toISOString().split("T")[0];

const validationSchema = Yup.object({
  subscription_type_id: Yup.string().required("Subscription type is required"),
  // lift_id: Yup.string().required("Lift is required"),
  asset_type_id: Yup.string().required("Asset Type is required"),
  asset_id: Yup.string().required("Asset is required"),
  start_date: Yup.string()
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      "Start date must be in the format YYYY-MM-DD"
    )
    .required("Start date is required")
    .test("is-valid-date", "Start date is not a valid date", (value) => {
      return !isNaN(new Date(value).getTime());
    }),
  end_date: Yup.string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, "End date must be in the format YYYY-MM-DD")
    .required("End date is required")
    .test("is-valid-date", "End date is not a valid date", (value) => {
      return !isNaN(new Date(value).getTime());
    })
    .test(
      "is-greater-than-today",
      "End date must be greater than today",
      (value) => {
        if (!value) return true; // Skips validation if the value is not set yet
        const endDate = new Date(value);
        return endDate > new Date(todayDate);
      }
    ),
  customer_id: Yup.string().required("User is required"),
});

const AddSubscriptionModal = ({ isOpen, onClose, fetchData }) => {
  const [users, setUsers] = useState(null);
  const { fetchError, fetchSuccess } = useSnackbar();
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [assetType, setAssetType] = React.useState([]);
  const [assets, setAssets] = React.useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);

  const [selectedAssetTypeId, setSelectedAssetTypeId] = useState(null);

  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [selectedAssetTypeValue, setSelectedAssetTypeValue] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const fetchAssetsData = useCallback(async () => {
    try {
      const response = await axios.get("/asset-types", {
        params: { is_active: 1 },
      });
      setAssetType(response?.data?.list || []);
    } catch (error) {
      console.error("Error fetching asset types:", error);
    }
  }, []);

  useEffect(() => {
    fetchAssetsData();
  }, [fetchAssetsData]);

  const fetchAssets = useCallback(async () => {
    if (selectedCustomer && selectedAssetTypeId) {
      try {
        const response = await axios.get(
          `/assets/${selectedAssetTypeId}/${selectedCustomer}`
        );
        setAssets(response?.data?.list || []);
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    }
  }, [selectedCustomer, selectedAssetTypeId]);

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);
  const fetchUsersData = async () => {
    try {
      const response = await axios.get("/customer");
      setUsers(response?.data?.list);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const fetchSubsciptionTypes = useCallback(async () => {
    try {
      const subscriptionTypes = await axios.get(
        `/subscription-types/assets/${selectedAssetType?.asset_type_id}`,
        {
          params: { is_active: 1 },
        }
      );
      setSubscriptionTypes(subscriptionTypes?.data?.list || []);
    } catch (error) {
      console.error("Error fetching asset types:", error);
    }
  }, [selectedAssetType?.asset_type_id]);

  useEffect(() => {
    fetchSubsciptionTypes();
  }, [fetchSubsciptionTypes]);
  // const subscriptionTypes = ["AMC", "AMC with Parts", "Free"];

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  const nextYear = new Date(today.setFullYear(today.getFullYear() + 1));
  const formattedNextYear = nextYear.toISOString().split("T")[0];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="otp-modal"
      aria-describedby="otp-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          minWidth: 500,
          minHeight: 220,
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          ...customScrollbarStyle,
        }}
      >
        <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
          Add New Subscription
        </SoftTypography>
        <Formik
          initialValues={{
            // lift_id: "",
            asset_type_id: "",
            asset_id: "",
            customer_id: "",
            subscription_type_id: "",
            start_date: formattedToday,
            end_date: formattedNextYear,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              delete values.asset_type_id;
              const response = await axios.post("/subscription", values);

              fetchData();
              onClose();
              fetchSuccess(response.data.message);
            } catch (error) {
              fetchError(error.response.data.error);
            }
          }}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      User
                    </SoftTypography>
                    <Autocomplete
                      options={users || []}
                      getOptionLabel={(option) =>
                        `${option.name} - (${option.phone})`
                      }
                      onChange={(event, value) => {
                        setFieldValue("asset_id", "");
                        setFieldValue("customer_id", value?.customer_id || "");
                        setSelectedCustomer(value?.customer_id);
                        setFieldValue("asset_type_id", "");
                        setFieldValue("subscription_type_id", "");
                        setSelectedAsset(null);
                        setSelectedAssetTypeValue(null);
                        setSelectedSubscription(null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.customer_id && touched.customer_id}
                          helperText={touched.customer_id && errors.customer_id}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Asset Type
                    </SoftTypography>
                    <Autocomplete
                      options={assetType || []}
                      getOptionLabel={(option) => option.asset_type_name}
                      value={selectedAssetTypeValue}
                      onChange={(event, value) => {
                        setFieldValue(
                          "asset_type_id",
                          value?.asset_type_id || ""
                        );
                        setSelectedAssetTypeId(value?.asset_type_id);
                        setSelectedAssetType(value);
                        setSelectedAssetTypeValue(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={errors.asset_type_id && touched.asset_type_id}
                          helperText={
                            touched.asset_type_id && errors.asset_type_id
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Asset
                    </SoftTypography>
                    <Autocomplete
                      options={assets || []}
                      value={selectedAsset}
                      getOptionLabel={(option) => option.asset_name}
                      onChange={(event, value) => {
                        setFieldValue("asset_id", value?.asset_id || "");
                        setSelectedAsset(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.asset_id && touched.asset_id}
                          helperText={touched.asset_id && errors.asset_id}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Subscription Type
                    </SoftTypography>
                    <Autocomplete
                      options={subscriptionTypes || []}
                      value={selectedSubscription}
                      getOptionLabel={(option) =>
                        option?.subscription_type_name
                      }
                      onChange={(event, value) => {
                        setFieldValue(
                          "subscription_type_id",
                          value?.subscription_type_id || ""
                        );
                        setSelectedSubscription(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={
                            errors.subscription_type_id &&
                            touched.subscription_type_id
                          }
                          helperText={
                            touched.subscription_type_id &&
                            errors.subscription_type_id
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Start Date
                    </SoftTypography>
                    <Field
                      className="date-field"
                      name="start_date"
                      as={TextField}
                      InputProps={{
                        inputProps: {
                          min: formattedToday,
                        },
                      }}
                      type="date"
                      fullWidth
                      variant="outlined"
                      error={errors.start_date && touched.start_date}
                      helperText={touched.start_date && errors.start_date}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      End Date
                    </SoftTypography>
                    <Field
                      className="date-field"
                      name="end_date"
                      as={TextField}
                      InputProps={{
                        inputProps: {
                          min: values?.start_date || formattedToday,
                        },
                      }}
                      type="date"
                      fullWidth
                      variant="outlined"
                      error={errors.end_date && touched.end_date}
                      helperText={touched.end_date && errors.end_date}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <SoftButton
                      className="success-btn"
                      variant="gradient"
                      color="success"
                      type="submit"
                    >
                      Save
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      className="cancel-btn"
                      variant="gradient"
                      color="warning"
                      onClick={onClose}
                    >
                      Cancel
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddSubscriptionModal;
