import * as React from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Card,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  pagination: true,
  paginationPosition: "top",
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const SubscriptionList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [assetTypes, setAssetTypes] = React.useState(null);
  const [assetTypeFilter, setAssetTypeFilter] = React.useState(null);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  const columns = [
    { name: "name", label: "Customer" },
    // { name: "lift_service_id", label: "Lift Id" },
    { name: "asset_service_id", label: "Asset Id" },
    { name: "asset_type_name", label: "Asset Type" },
    { name: "subscription_type", label: "Subscription Type" },
    {
      name: "start_date",
      label: "Start Date",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    {
      name: "end_date",
      label: "End Date",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <span
            className="active-btn"
            style={{
              borderColor: value ? "#00bdaa" : "#fe346e",
            }}
          >
            {value ? "Active" : "Inactive"}
          </span>
        ),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const subscription =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box>
              {subscription.is_active ? (
                <IconButton
                  aria-label="edit"
                  onClick={() =>
                    navigate(
                      `/edit-subscription/${subscription?.subscription_id}`
                    )
                  }
                >
                  <EditIcon />
                </IconButton>
              ) : (
                ""
              )}
            </Box>
          );
        },
      },
    },
  ];

  const fetchData = React.useCallback(async () => {
    try {
      const response = await axios.get("/subscription", {
        params: {
          filters: {
            asset_type_id: assetTypeFilter?.asset_type_id,
          },
        },
      });

      setRows(response?.data);
    } catch (error) {
      setError(error);
    }
  }, [assetTypeFilter]);

  React.useEffect(() => {
    fetchData();
  }, [assetTypeFilter, fetchData]);
  const fetchAssets = async () => {
    try {
      const assetTypesData = await axios.get("/asset-types");
      setAssetTypes(assetTypesData?.data?.list);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  React.useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div style={{ display: "block" }}>
          <Grid>
            <FormControl sx={{ paddingLeft: "10px", marginBottom: "3px" }}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                sx={{ paddingLeft: "10px" }}
              >
                Asset Types
              </SoftTypography>
              <Autocomplete
                options={assetTypes || []}
                getOptionLabel={(option) => option.asset_type_name}
                value={assetTypeFilter}
                onChange={(event, value) => {
                  setAssetTypeFilter(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </FormControl>
            <SoftButton
              variant="gradient"
              color="info"
              onClick={() => navigate(`/add-subscription`)}
              sx={{ float: "right" }}
            >
              Add Subscription
            </SoftButton>
          </Grid>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-subscriptions"
              title={"Manage Subscriptions"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          {error && <div>Error: {error.message}</div>}
        </SoftBox>
      </Card>
    </DashboardLayout>
  );
};

export default SubscriptionList;
