import React, { useState } from "react";
import { Grid, Card, Typography, Tabs, Tab, Box } from "@mui/material";

import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AssetTypeList from "layouts/AssetTypes/AssetTypeList";
import CategoriesList from "layouts/Categories/CategoriesList";
import SubscriptionTypesList from "layouts/SubscriptionTypes/SubscriptionTypesList";

const TypesPage = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        sx={{
          maxWidth: "100vw",
          width: "100%",
          padding: "30px",
          maxHeight: "70%",
        }}
      >
        <SoftBox mt={5} md={12} lg={12}>
          <Grid justifyContent="center">
            <Grid item xs={12} md={8} lg={6}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                marginBottom={2}
              >
                <Grid item>
                  <Typography variant="h5" component="div" gutterBottom>
                    All Types
                  </Typography>
                </Grid>
                {/* {tabIndex === 1 && (
                    <Grid item>
                      <Button
                        className="success-btn"
                        type="submit"
                        variant="contained"
                        onClick={handleOpen}
                        color="success"
                      >
                        Add Address
                      </Button>
                    </Grid>
                  )} */}
              </Grid>
              <Box className="profile-tabs">
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Asset Types" />
                  <Tab label="Subscription Types" />
                  <Tab label="Category Types" />
                </Tabs>
                <Box mt={3}>
                  {tabIndex === 0 && <AssetTypeList />}
                  {tabIndex === 1 && (
                    <h1>
                      <SubscriptionTypesList />
                    </h1>
                  )}
                  {tabIndex === 2 && <CategoriesList />}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
    </DashboardLayout>
  );
};

export default TypesPage;
