import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import axios from "../../api";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import * as Yup from "yup";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461", // Lighter black color
    borderRadius: "8px", // Curved border
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666", // Lighter black color on hover
  },
};

// Update validation schema to use asset_type_name
const cityValidationSchema = Yup.object({
  asset_type_name: Yup.string().required("Asset is required"),
});

const AddAssetType = ({ isOpen, onClose, fetchData }) => {
  const { fetchError, fetchSuccess } = useSnackbar();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="otp-modal"
      aria-describedby="otp-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          minWidth: 500,
          minHeight: 220,
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          ...customScrollbarStyle,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Add Asset
        </Typography>
        <Formik
          initialValues={{
            asset_type_name: "", // Update to match your input field
          }}
          validationSchema={cityValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              const response = await axios.post("/asset-types", values);
              fetchData();
              onClose();
              fetchSuccess(response.data.message);
            } catch (error) {
              fetchError(error.response.data.error);
            }
          }}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      placeholder="Asset"
                      name="asset_type_name" // Ensure this matches your input field
                      value={values.asset_type_name} // Match the key in initialValues
                      onChange={handleChange}
                      error={
                        !!errors.asset_type_name && touched.asset_type_name
                      } // Validate against asset_type_name
                      helperText={
                        touched.asset_type_name && errors.asset_type_name
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="success-btn"
                      sx={{
                        position: "relative",
                        minWidth: 100,
                      }}
                      variant="contained"
                      type="submit"
                      color="success"
                    >
                      Save
                    </Button>
                    <Button
                      className="cancel-btn"
                      sx={{
                        position: "relative",
                        minWidth: 100,
                        ml: 2.5,
                      }}
                      variant="contained"
                      color="warning"
                      onClick={onClose}
                      type="button"
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddAssetType;
