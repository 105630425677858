import React, { useCallback, useEffect, useState } from "react";
import axios from "../../api";
import {
  Button,
  TextField,
  Grid,
  Box,
  Modal,
  Autocomplete,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import AWS from "aws-sdk";
import { useDropzone } from "react-dropzone";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461", // Lighter black color
    borderRadius: "8px", // Curved border
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666", // Lighter black color on hover
  },
};

const validationSchema = Yup.object({
  address_id: Yup.string().required("Address is required"),
  asset_name: Yup.string().required("Asset name is required"),
  customer_id: Yup.string().required("Customer is required"),
  asset_purchased_date: Yup.string()
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      "Purchased date must be in the format YYYY-MM-DD"
    )
    .required("Asset Purchased date is required")
    .test("is-valid-date", "Purchased date is not a valid date", (value) => {
      return !isNaN(new Date(value).getTime());
    }),
  asset_type_id: Yup.string().required("Asset Type is required"),
});

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const EditAsset = ({ isOpen, onClose, assetData, fetchData }) => {
  const { fetchError, fetchSuccess } = useSnackbar();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [assets, setAssets] = useState([]);

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  const dropzone = useDropzone({
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "video/mp4": [".mp4"],
    },
    onDrop: (acceptedFiles) => {
      // Append new files to the existing uploadedFiles array
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(() => {
    setUploadedFiles(dropzone.acceptedFiles);
  }, [dropzone.acceptedFiles]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchAssets = useCallback(async () => {
    try {
      const response = await axios.get("/asset-types", {
        params: {
          is_active: 1,
        },
      });
      const assetData =
        response.data.list && Array.isArray(response.data.list)
          ? response.data.list
          : [];

      setAssets(assetData);
    } catch (error) {
      console.error(error.response?.data?.error);
    }
  }, []);

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="otp-modal"
      aria-describedby="otp-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          minWidth: 500,
          minHeight: 220,
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          ...customScrollbarStyle,
        }}
      >
        <Typography variant="h5" style={{ marginBottom: "18px" }}>
          Edit Asset
        </Typography>
        <Formik
          initialValues={{
            address_id: assetData?.address_id || "",
            asset_name: assetData?.asset_name || "",
            customer_id: assetData?.customer_id || null,
            asset_purchased_date: assetData?.asset_purchased_date
              ? formatDate(assetData?.asset_purchased_date)
              : "",
            asset_type_id: assetData?.asset_type_id || "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setSubmitting(true);

            try {
              if (uploadedFiles.length > 0) {
                const file = uploadedFiles[0];
                const fileName = `${Date.now()}-${file.name}`;
                values.asset_image = fileName;
                const params = {
                  Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                  Key: fileName,
                  Body: file,
                  ContentType: file.type,
                };
                let type = file.type.split("/")[0];
                if (type === "image") {
                  type = "Photo";
                }
                values.image_type = type;
                s3.upload(params, async (err, data) => {
                  if (err) {
                    console.error("Error uploading file:", err);
                    setSubmitting(false);
                  } else {
                    try {
                      const response = await axios.put(
                        `/assets/${assetData?.asset_id}`,
                        values
                      );
                      fetchSuccess(response.data.message);
                      fetchData();
                      onClose();
                    } catch (error) {
                      fetchError(error.response.data.error);
                    } finally {
                      setSubmitting(false);
                    }
                  }
                });
              } else {
                try {
                  const response = await axios.put(
                    `/assets/${assetData?.asset_id}`,
                    values
                  );
                  fetchSuccess(response.data.message);
                  fetchData();
                  onClose();
                  setSubmitting(false);
                } catch (error) {
                  fetchError(error.response.data.error);
                } finally {
                  setSubmitting(false);
                }
              }
            } catch (error) {
              fetchError(error.response.data.error);
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Customer
                    </SoftTypography>
                    <TextField
                      name="customer_id"
                      value={`${assetData.customer_name} - (${assetData.customer_phone})`}
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Asset Type
                  </SoftTypography>
                  <Autocomplete
                    options={assets || []}
                    getOptionLabel={(option) => option.asset_type_name || ""}
                    value={
                      assets.find(
                        (asset) => asset.asset_type_id === values.asset_type_id
                      ) || null
                    }
                    onChange={(event, value) => {
                      setFieldValue(
                        "asset_type_id",
                        value ? value.asset_type_id : ""
                      );
                      setFieldValue("asset_name", "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={!!errors.asset_type_id && touched.asset_type_id}
                        helperText={
                          touched.asset_type_id && errors.asset_type_id
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Asset Name
                    </SoftTypography>
                    <Field
                      name="asset_name"
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      error={!!errors.asset_name && touched.asset_name}
                      helperText={touched.asset_name && errors.asset_name}
                    />
                  </SoftBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  {/* <FormControl fullWidth> */}
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Asset Purchased Date
                  </SoftTypography>
                  <Field
                    className="date-field"
                    name="asset_purchased_date"
                    as={TextField}
                    type="date"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        max: formattedToday,
                      },
                    }}
                    error={
                      errors.asset_purchased_date &&
                      touched.asset_purchased_date
                    }
                    helperText={
                      touched.asset_purchased_date &&
                      errors.asset_purchased_date
                    }
                  />
                  {/* </FormControl> */}
                </Grid>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address
                    </SoftTypography>
                    <TextField
                      name="address_id"
                      fullWidth
                      value={`${assetData.address_type}-${assetData.city_name}-${assetData.region_name}`}
                      variant="outlined"
                      disabled
                    />
                  </SoftBox>
                </Grid>

                <Grid item xs={12}>
                  <div className={assetData?.asset_image ? "upload-media" : ""}>
                    {assetData?.asset_image && !(uploadedFiles.length > 0) ? (
                      <div
                        className="media-container"
                        style={{ position: "relative" }}
                      >
                        {assetData?.image_type === "Photo" ? (
                          <img
                            className="upload-img"
                            src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${assetData?.asset_image}`}
                            alt="asset-media"
                          />
                        ) : (
                          <video
                            className="upload-video"
                            controls
                            width="100%"
                            height="140px"
                          >
                            <source
                              src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${assetData?.asset_image}`}
                              type="video/mp4"
                            />
                          </video>
                        )}
                      </div>
                    ) : (
                      uploadedFiles.length > 0 &&
                      uploadedFiles[0]?.preview && (
                        <div
                          className="media-container"
                          style={{ position: "relative" }}
                        >
                          {uploadedFiles[0]?.type.startsWith("image") ? (
                            <img
                              src={uploadedFiles[0]?.preview}
                              alt="media"
                              className="upload-img"
                            />
                          ) : (
                            <video
                              className="upload-video"
                              controls
                              width="100%"
                              height="140px"
                            >
                              <source
                                src={uploadedFiles[0]?.preview}
                                type="video/mp4"
                              />
                            </video>
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <Box
                    sx={{ cursor: "pointer", width: "fit-content" }}
                    {...dropzone.getRootProps()}
                  >
                    <Box>
                      <label htmlFor="image-upload-input">
                        <input
                          name="asset_image"
                          {...dropzone.getInputProps({
                            name: "asset_image",
                          })}
                        />
                        <Button
                          className="upload-btn"
                          component="span"
                          variant="contained"
                          color="primary"
                        >
                          Add/Upload File
                        </Button>
                      </label>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="success-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    variant="contained"
                    disabled={submitting}
                    type="submit"
                    color="success"
                  >
                    Update
                  </Button>
                  <Button
                    className="cancel-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                      ml: 2.5,
                    }}
                    variant="contained"
                    color="warning"
                    onClick={onClose}
                    type="button"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default EditAsset;
