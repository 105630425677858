import React from "react";

const FormatStatusTrackerData = ({ trackingData }) => {
  let data = [];

  if (typeof trackingData === "string") {
    try {
      data = JSON.parse(trackingData);
    } catch (error) {
      console.error("Error parsing trackingData:", error);
    }
  }

  const styles = {
    trackerContainer: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      padding: "10px",
      border: "1px solid #e0e0e0",
      borderRadius: "5px",
      backgroundColor: "#f9f9f9",
      height: "300px",
      width: "100%",
      margin: "0 auto",
      marginBottom: "40px",
    },
    trackerItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: "16px",
      padding: "8px",
      borderRadius: "5px",
      backgroundColor: "#ffffff",
      border: "1px solid #e0e0e0",
      height: "auto",
    },
    dot: {
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "gray",
      marginRight: "10px",
      flexShrink: 0,
    },
    latestDot: {
      backgroundColor: "green",
    },
    messageContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    messageText: {
      fontWeight: 500,
      fontSize: "14px",
      color: "#000",
      marginRight: "10px",
      textAlign: "center",
    },
    detailsText: {
      fontSize: "12px",
      color: "gray",
      textAlign: "center",
    },
    noDataText: {
      color: "red",
      fontStyle: "italic",
      textAlign: "center",
      margin: "20px 0",
    },
  };

  return (
    <div style={styles.trackerContainer}>
      {Array.isArray(data) && data.length > 0 ? (
        [...data].reverse().map((item, index) => (
          <div key={index} style={styles.trackerItem}>
            <div
              style={{
                ...styles.dot,
                ...(index === 0 ? styles.latestDot : {}),
              }}
            />
            <div style={styles.messageContainer}>
              <span style={styles.messageText}>{item?.message}</span>
              <span style={styles.detailsText}>
                {item?.Date || ""}, {item?.changedBy || ""},
                {item?.employeePhone || ""}
              </span>
            </div>
          </div>
        ))
      ) : (
        <span style={styles.noDataText}>No data available.</span>
      )}
    </div>
  );
};

export default FormatStatusTrackerData;
