import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import axios from "../../api";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftButton from "components/SoftButton";
import { useSelector } from "react-redux";
import StatusTracker from "examples/ReuseFunctions/StatusTracker";

// Validation schema
const validationSchema = Yup.object({
  status_id: Yup.string().required("Status is required"),
  pending_reason: Yup.string().nullable(),
});

const EmployeeEditTicket = ({ onClose, employeeTicketData, fetchData }) => {
  const user = useSelector((state) => state.auth.user);
  const { fetchError, fetchSuccess } = useSnackbar();
  return (
    <Formik
      initialValues={{
        status_id: employeeTicketData?.status_id || "",
        pending_reason: employeeTicketData?.pending_reason || null,
        onhold_reason: null,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const onHoldReason = values?.onhold_reason || "On-Hold from Employee";
        const pendingReason = values?.pending_reason || "Pending from Employee";
        delete values.onhold_reason;
        if (values.status_id === employeeTicketData?.status_id) {
          delete values.status_id;
        }
        const ticketData = {
          ...values,
        };
        const trackerData = StatusTracker(
          employeeTicketData?.status_tracker,
          values?.status_id === 4
            ? onHoldReason
            : values?.status_id === 5
            ? pendingReason
            : "Ticket is completed",
          values?.status_id === 4
            ? "On-hold"
            : values?.status_id === 5
            ? "Pending"
            : "Done",
          values?.status_id,
          user?.name,
          employeeTicketData?.employee_name,
          employeeTicketData?.employee_phone || ""
        );
        ticketData.status_tracker = trackerData;
        try {
          const response = await axios.put(
            `/tickets/${employeeTicketData.ticket_id}`,
            { ticketData }
          );
          fetchData();
          onClose();
          fetchSuccess(response?.data?.message);
        } catch (err) {
          fetchError(err.response.data.error);
        }
      }}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SoftBox>
                <Typography variant="h5">Status</Typography>
                <RadioGroup
                  aria-label="notification_preference"
                  name="type"
                  value={values.status_id}
                  row
                >
                  <FormControlLabel
                    value="6"
                    onChange={(event) => {
                      setFieldValue(
                        "status_id",
                        event.target.value === "6" ? 6 : 4
                      );
                    }}
                    control={<Radio />}
                    label="Done"
                  />
                  <FormControlLabel
                    value="4"
                    onChange={(event) => {
                      setFieldValue(
                        "status_id",
                        event.target.value === "4" ? 4 : 6
                      );
                    }}
                    control={<Radio />}
                    label="On-hold"
                  />
                  <FormControlLabel
                    value="5"
                    onChange={(event) => {
                      setFieldValue(
                        "status_id",
                        event.target.value === "5" ? 5 : 6
                      );
                    }}
                    control={<Radio />}
                    label="Pending"
                  />
                  {employeeTicketData?.status_id === 5 && (
                    <FormControlLabel
                      value="3"
                      onChange={(event) => {
                        setFieldValue(
                          "status_id",
                          event.target.value === "3" ? 3 : 6
                        );
                      }}
                      control={<Radio />}
                      label="In-Progress"
                    />
                  )}
                </RadioGroup>
              </SoftBox>
            </Grid>
            {[5].includes(values.status_id) && (
              <Grid item xs={12}>
                <SoftBox>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Pending Reason
                  </SoftTypography>
                  <TextField
                    name="pending_reason"
                    variant="outlined"
                    onChange={(event) => {
                      const data = event.target.value;
                      setFieldValue("pending_reason", data ? data : null);
                    }}
                    fullWidth
                    error={!!errors.pending_reason && touched.pending_reason}
                    helperText={touched.pending_reason && errors.pending_reason}
                  />
                </SoftBox>
              </Grid>
            )}
            {[4].includes(values.status_id) && (
              <Grid item xs={12}>
                <SoftBox>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    On-Hold Reason
                  </SoftTypography>
                  <TextField
                    name="onhold_reason"
                    variant="outlined"
                    onChange={(event) => {
                      const data = event.target.value;
                      setFieldValue("onhold_reason", data ? data : null);
                    }}
                    fullWidth
                    error={errors.onhold_reason && touched.onhold_reason}
                    helperText={touched.onhold_reason && errors.onhold_reason}
                  />
                </SoftBox>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <SoftButton
                  className="success-btn"
                  variant="gradient"
                  color="success"
                  type="submit"
                >
                  Update
                </SoftButton>
              </Grid>
              <Grid item>
                <SoftButton
                  className="cancel-btn"
                  variant="gradient"
                  color="warning"
                  onClick={onClose}
                >
                  Cancel
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EmployeeEditTicket;
