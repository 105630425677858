import React, { useEffect, useState, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Typography,
  Card,
  Tooltip,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftTypography from "components/SoftTypography";

const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#f5f5f5",
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const EmployeeClosedTickets = () => {
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);
  const [employeeFilter, setEmployeeFilter] = React.useState(null);
  const [employees, setEmployees] = React.useState(null);

  const fetchTickets = useCallback(async () => {
    try {
      const response = await axios.get(`/tickets`, {
        params: {
          status_id: 7,
          filters: {
            employee_id: employeeFilter?.employee_id,
          },
        },
      });
      setRows(response.data.list);
    } catch (error) {
      setError(error);
      console.error("Error fetching tickets:", error);
    }
  }, [employeeFilter]);

  useEffect(() => {
    fetchTickets();
  }, [employeeFilter, fetchTickets]);
  const fetchEmployees = async () => {
    try {
      const employeeData = await axios.get("/employee");
      setEmployees(employeeData?.data);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  React.useEffect(() => {
    fetchEmployees();
  }, []);
  const columns = [
    {
      name: "employee_name",
      label: "Employee",
      options: {
        customBodyRender: (value) => {
          return <span>{value ? value : "Unassigned"}</span>;
        },
      },
    },
    { name: "ticket_service_id", label: "Service ID" },
    { name: "customer_name", label: "Customer" },
    { name: "customer_phone", label: "Phone" },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const data = tableMeta.tableData[tableMeta.rowIndex];
          const address = `${value}, ${data.city_name}, ${data.state_name}`;
          return (
            <Tooltip title={address}>
              <span>
                {address.length > 20
                  ? `${address.substring(0, 20)}...`
                  : address}
              </span>
            </Tooltip>
          );
        },
      },
    },
    { name: "description", label: "Description" },
    { name: "category_name", label: "Category" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "20px auto", maxWidth: "2000px" }}
      >
        <Typography variant="h4" gutterBottom>
          Closed Tickets
        </Typography>
        <div
          style={{
            display: "block",
            justifyContent: "flex-end",
            marginLeft: "10px",
            float: "right",
          }}
        >
          <Grid style={{ float: "left" }}>
            <FormControl sx={{ paddingRight: "10px" }}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                sx={{ paddingLeft: "10px" }}
              >
                Employee
              </SoftTypography>
              <Autocomplete
                options={employees || []}
                getOptionLabel={(option) => option.name || ""}
                value={employeeFilter}
                onChange={(event, value) => {
                  setEmployeeFilter(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </FormControl>
          </Grid>
        </div>

        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={rows} columns={columns} options={options} />
        </ThemeProvider>

        {error && <div style={{ color: "red" }}>Error: {error.message}</div>}
      </Card>
    </DashboardLayout>
  );
};

export default EmployeeClosedTickets;
