import { useState, useEffect } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard React examples
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";
// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";
import { Box } from "@mui/material";
import AllPages from "examples/ReuseFunctions/AllPages";
import innovativelogo from "../../../assets/images/innovative-Logo.png";
function DefaultNavbar({ transparent, light, action }) {
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openMobileNavbar = ({ currentTarget }) =>
    setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.xl) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);
  const { pagesData, logo } = AllPages();

  return (
    <Box className="default-header">
      <Container>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SoftBox
            className="logo"
            component={Link}
            // to="/"
            py={transparent ? 1.5 : 0.75}
            lineHeight={1}
          >
            <img
              src={
                logo
                  ? `${process.env.REACT_APP_CLOUD_FRONT_URL}/${logo}`
                  : innovativelogo
              }
              // src={innovativelogo}
              alt="logo"
              width="50px"
              height="30px"
            />
          </SoftBox>
          <SoftBox
            color="inherit"
            display={{ xs: "none", lg: "flex" }}
            m={0}
            p={0}
          >
            {pagesData &&
              pagesData?.map((data) => {
                return (
                  <DefaultNavbarLink
                    icon="info"
                    name={data?.page_name}
                    route={`/${data?.page_route}`}
                    light={light}
                  />
                );
              })}
            <DefaultNavbarLink
              icon="info"
              name="About us"
              route="/about"
              light={light}
            />

            <DefaultNavbarLink
              icon="contacts"
              name="Contact us"
              // route="/admin/sign-in"
              route="/contact"
              light={light}
            />
            <DefaultNavbarLink
              icon="account_circle"
              name="Employee sign in"
              route="/employee/sign-in"
              // route="/sign-in"
              light={light}
            />
          </SoftBox>
          <SoftBox
            display={{ xs: "inline-block", lg: "none" }}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color="inherit"
            sx={{ cursor: "pointer" }}
            onClick={openMobileNavbar}
          >
            <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
          </SoftBox>
        </SoftBox>

        {mobileView && (
          <DefaultNavbarMobile
            open={mobileNavbar}
            close={closeMobileNavbar}
            pagesData={pagesData}
          />
        )}
      </Container>
    </Box>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
