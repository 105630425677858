import Dashboard from "layouts/dashboard";
import Profile from "./layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
import AdminSignIn from "./layouts/authentication/admin-sign-in/AdminSignIn";
// import SignUp from "layouts/authentication/sign-up";
import ForgotPassword from "layouts/authentication/passwords/ForgotPassword";
import PasswordReset from "layouts/authentication/passwords/PasswordReset";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CategoryIcon from "@mui/icons-material/Category";
import EmailIcon from "@mui/icons-material/Email";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import CustomerSupport from "examples/Icons/CustomerSupport";
import TicketList from "layouts/Ticket/TicketList";
import AdminTicketList from "layouts/AdminTickets/AdminTickesList";
import AddTicket from "layouts/Ticket/AddTicket";
import EditTicket from "layouts/Ticket/EditTicket";
import AddCustomers from "layouts/customers/AddCustomers";
import EditCustomers from "layouts/customers/EditCustomers";
import CustomerList from "layouts/customers/CustomerList";
import AddEmployee from "layouts/employees/AddEmployee";
import EditEmployee from "layouts/employees/EditEmployee";
import EmployeesList from "layouts/employees/EmployeesList";
import AddSupervisor from "layouts/supervisors/AddSupervisor";
import EditSupervisor from "layouts/supervisors/EditSupervisor";
import SupervisorsList from "layouts/supervisors/SupervisorsList";
import AddSubscription from "layouts/Subscriptions/AddSubscription";
import SubscriptionList from "layouts/Subscriptions/SubscriptionList";
import EditSubscription from "layouts/Subscriptions/EditSubscription";
import AdminEditTicket from "layouts/AdminTickets/AdminEditTicket";
import AdminAddTicket from "layouts/AdminTickets/AdminAddTicket";
import EmployeeTicketList from "layouts/EmployeeTickets/EmployeeTicketsList";
import CustomerAssetsList from "layouts/CustomerAssets/CustomerAssetsList";
// import LiftsList from "layouts/Lifts/LiftsList";
// import AddLift from "layouts/Lifts/AddLift";
import ViewTickets from "layouts/AdminTickets/ViewTickets";
// import EditLift from "layouts/Lifts/EditLift";
import Locations from "../src/layouts/Locations/index";
// import AdminLiftsList from "layouts/AdminLifts/AdminLiftsList";
import ViewAddresses from "layouts/customers/ViewAddresses";
import TemplatesList from "layouts/EmailTemplates/TemplatesList";
import AdminProfile from "layouts/AdminProfile";
import Home from "layouts/Home";
import About from "layouts/Home/About";
import Contact from "layouts/Home/Contact";
import PrivacyPolicy from "layouts/Home/PrivacyPolicy";
import TermsConditions from "layouts/Home/TermsConditions";
import AddPage from "layouts/Pages/AddPage";
import PagesList from "layouts/Pages/PagesList";
import ViewTicket from "layouts/Ticket/ViewTicket";
import EmployeeViewTicket from "layouts/EmployeeTickets/EmployeeViewTicket";
import GeneralPreferences from "layouts/GeneralPreferences.js";
import CustomerViewAsset from "layouts/CustomerAssets/CustomerViewAsset";
// import ViewLift from "layouts/AdminLifts/ViewLift";
// import LiftView from "layouts/Lifts/ViewLift";
import EditPage from "layouts/Pages/EditPage";
import AssetsList from "layouts/Assets/AssetsList";
import TypesPage from "layouts/Types";
import EventsCalender from "layouts/EmployeeTickets/EventsCalender";
import EventsOverview from "layouts/EmployeeTickets/EventsOverview";
import EmployeeClosedTickets from "layouts/AdminTickets/EmployeeClosedTickets";
// import GoogleMaps from "layouts/customers/GoogleMaps";
const publicRoutes = [
  {
    type: "collapse",
    name: "Home Page",
    route: "/home",
    component: <Home />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "About Page",
    route: "/about",
    component: <About />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Contact Page",
    route: "/contact",
    component: <Contact />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Privacy Policy Page",
    route: "/privacy-policy",
    component: <PrivacyPolicy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Terms Conditions Page",
    route: "/terms-conditions",
    component: <TermsConditions />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Admin Sign In",
    route: "admin/sign-in",
    component: <AdminSignIn />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   route: "/sign-in",
  //   component: <SignIn />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   route: "/sign-up",
  //   component: <SignUp />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Forget Password",
    route: "/forgot-password",
    component: <ForgotPassword />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reset Password",
    route: "/reset-password/:token",
    component: <PasswordReset />,
    noCollapse: true,
  },
];

const privateRoutes = [
  {
    route: "/add-ticket",
    component: <AddTicket />,
    roles: ["admin"],
    noCollapse: true,
  },
  {
    route: "/add-customer",
    component: <AddCustomers />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  // {
  //   route: "/google-maps",
  //   component: <GoogleMaps />,
  //   roles: ["admin", "employee", "customer", "supervisor"],
  //   noCollapse: true,
  // },
  {
    route: "/edit-customer/:id",
    component: <EditCustomers />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    route: "/add-employee",
    component: <AddEmployee />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    route: "/edit-employee/:id",
    component: <EditEmployee />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    route: "/add-supervisor",
    component: <AddSupervisor />,
    roles: ["admin"],
    noCollapse: true,
  },
  {
    route: "/edit-supervisor/:id",
    component: <EditSupervisor />,
    roles: ["admin"],
    noCollapse: true,
  },
  {
    route: "/add-subscription",
    component: <AddSubscription />,
    roles: ["admin"],
    noCollapse: true,
  },
  {
    route: "/admin/employee-closed-tickets",
    component: <EmployeeClosedTickets />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    route: "/edit-subscription/:id",
    component: <EditSubscription />,
    roles: ["admin"],
    noCollapse: true,
  },
  {
    route: "/customer/edit-ticket/:id",
    component: <EditTicket />,
    roles: ["admin"],
    noCollapse: true,
  },
  {
    route: "/admin/add-ticket",
    component: <AdminAddTicket />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    route: "/admin/edit-ticket/:id",
    component: <AdminEditTicket />,
    roles: ["admin", "supervisor", "employee"],
    noCollapse: true,
  },
  {
    route: "/admin/ticket-view/:id",
    component: <ViewTickets />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    route: "/customer/ticket-view/:id",
    component: <ViewTicket />,
    roles: ["customer"],
    noCollapse: true,
  },
  {
    route: "/employee/ticket-view/:id",
    component: <EmployeeViewTicket />,
    roles: ["employee"],
    noCollapse: true,
  },
  //{
  // route: "/admin/lifts-view/:id",
  // component: <ViewLift />,
  // roles: ["admin"],
  // noCollapse: true,
  //},
  //{
  //  route: "/customer/lifts-view/:id",
  // component: <LiftView />,
  // roles: ["customer"],
  // noCollapse: true,
  //},
  {
    route: "/customer/assets-view/:id",
    component: <CustomerViewAsset />,
    roles: ["customer"],
    noCollapse: true,
  },
  {
    route: "/address-view/:id",
    component: <ViewAddresses />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    route: "/add-page",
    component: <AddPage />,
    roles: ["admin"],
    noCollapse: true,
  },

  {
    route: "/edit-page/:id",
    component: <EditPage />,
    roles: ["admin"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/customer/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    roles: ["customer"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/admin/profile",
    icon: <CustomerSupport size="12px" />,
    component: <AdminProfile />,
    roles: ["admin", "supervisor", "employee"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    roles: ["admin", "supervisor", "employee"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "General Preference",
    key: "general",
    route: "/general-preference",
    icon: <CustomerSupport size="12px" />,
    component: <GeneralPreferences />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Types",
    key: "typesPage",
    route: "/types",
    icon: <CategoryIcon size="12px" />,
    component: <TypesPage />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Supervisors",
    key: "supervisorslist",
    route: "/supervisors-list",
    icon: <SupervisorAccountIcon size="12px" />,
    component: <SupervisorsList />,
    roles: ["admin"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Employees",
    key: "employeeslist",
    route: "/employees-list",
    icon: <SupervisorAccountIcon size="12px" />,
    component: <EmployeesList />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customerslist",
    route: "/customers-list",
    icon: <SupervisorAccountIcon size="12px" />,
    component: <CustomerList />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Subscription",
    key: "subscriptionlist",
    route: "/subscriptions-list",
    icon: <SubscriptionsIcon size="12px" />,
    component: <SubscriptionList />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  //{
  //type: "collapse",
  //name: "Lifts",
  // key: "liftslist",
  // route: "/admin/lifts-list",
  // icon: <Office size="12px" />,
  // component: <AdminLiftsList />,
  // roles: ["admin"],
  // noCollapse: true,
  //},
  {
    type: "collapse",
    name: "Tickets",
    key: "ticketslist",
    route: "/customer/tickets-list",
    icon: <ConfirmationNumberIcon size="12px" />,
    component: <TicketList />,
    roles: ["customer"],
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Assets",
    key: "assetList",
    route: "/assets-list",
    icon: <CategoryIcon size="12px" />,
    component: <AssetsList />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Tickets",
    key: "adminTicketslist",
    route: "/admin/tickets-list",
    icon: <ConfirmationNumberIcon size="12px" />,
    component: <AdminTicketList />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Closed Tickets",
    key: "closedticketslist",
    route: "/admin/employee-closed-tickets",
    icon: <ConfirmationNumberIcon size="12px" />,
    component: <EmployeeClosedTickets />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Templates",
    key: "templateslist",
    route: "/templates-list",
    icon: <EmailIcon size="12px" />,
    component: <TemplatesList />,
    roles: ["admin", "supervisor"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Locations",
    key: "Locationslist",
    route: "/locations",
    icon: <FmdGoodIcon size="12px" />,
    component: <Locations />,
    roles: ["admin"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Tickets",
    key: "employeeTicketslist",
    route: "/employee-tickets-list",
    icon: <Office size="12px" />,
    component: <EmployeeTicketList />,
    roles: ["employee"],
    noCollapse: true,
  },
  //{
  //  type: "collapse",
  //  name: "Lifts",
  //  key: "liftslist",
  //  route: "/customer/lifts-list",
  //  icon: <Office size="12px" />,
  //  component: <LiftsList />,
  //  roles: ["customer"],
  // noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Assets",
    key: "customerassetslist",
    route: "/customer/assets-list",
    icon: <Office size="12px" />,
    component: <CustomerAssetsList />,
    roles: ["customer"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Calender",
    key: "employeecalender",
    route: "/employee/calender",
    icon: <Office size="12px" />,
    component: <EventsCalender />,
    roles: ["employee"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Events Overview",
    key: "eventsoverview",
    route: "/employee/eventsoverview",
    icon: <Office size="12px" />,
    component: <EventsOverview />,
    roles: ["employee"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    route: "/pages-list",
    icon: <CustomerSupport size="12px" />,
    component: <PagesList />,
    roles: ["admin"],
    noCollapse: true,
  },

  // { type: "title", title: "Account Pages", key: "account-pages" },
];
export { publicRoutes, privateRoutes };
