import React, { useCallback, useEffect, useState } from "react";
import axios from "../../api";
import {
  Card,
  TextField,
  FormControl,
  CircularProgress,
  Grid,
  Autocomplete,
  FormControlLabel,
  Switch,
  FormHelperText,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import Locations from "examples/ReuseFunctions/Locations";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .typeError("Enter a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Enter a valid phone number")
    .required("Phone number is required"),
  state_id: Yup.string().required("State is required"),
  city_id: Yup.string().required("City is required"),
  region_id: Yup.string().required("Area is required"),
  address: Yup.string().required("Address is required"),
  skill_set: Yup.string().required("Skills are required"),
  asset_type_id: Yup.string().required("Asset Type is required"),
});

const EditEmployee = () => {
  const { id } = useParams();
  const { states, cities, areas, setSelectedState, setSelectedCity } =
    Locations();
  const navigate = useNavigate();

  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialState, setInitialState] = useState(null);
  const [initialCity, setInitialCity] = useState(null);
  const [initialRegion, setInitialRegion] = useState(null);
  const [assets, setAssets] = useState([]);

  const { fetchError, fetchSuccess } = useSnackbar();

  const fetchEmployee = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/employee/${id}`);
      const { data } = response;
      setSelectedState(data?.state_id);
      setSelectedCity(data?.city_id);
      setInitialState({
        state_id: data?.state_id,
        state_name: data?.state_name,
      });
      setInitialCity({
        city_id: data?.city_id,
        city_name: data?.city_name,
      });
      setInitialRegion({
        region_id: data?.region_id,
        region_name: data?.region_name,
      });

      setEmployee(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [id, setSelectedState, setSelectedCity]);

  useEffect(() => {
    fetchEmployee();
  }, [fetchEmployee]);
  useEffect(() => {
    if (employee) {
      setSelectedState(employee?.state_id);
      setSelectedCity(employee?.city_id);
    }
  }, [employee, setSelectedState, setSelectedCity]);
  const fetchAssets = useCallback(async () => {
    try {
      const response = await axios.get("/asset-types", {
        params: {
          is_active: 1,
        },
      });
      const assetData =
        response.data.list && Array.isArray(response.data.list)
          ? response.data.list
          : [];

      setAssets(assetData);
    } catch (error) {
      console.error(error.response?.data?.error);
    }
  }, []);
  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <CircularProgress />
      ) : (
        <Card style={{ padding: "30px", width: "100%", borderRadius: "8px" }}>
          <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
            Edit Employee
          </SoftTypography>
          <Formik
            enableReinitialize
            initialValues={{
              name: employee?.name || "",
              email: employee?.email || "",
              phone: employee?.phone || "",
              state_id: employee?.state_id || "",
              city_id: employee?.city_id || "",
              region_id: employee?.region_id || "",
              address: employee?.address || "",
              skill_set: employee?.skill_set || "",
              asset_type_id: employee?.asset_type_id || "",
              is_active: employee?.is_active === 1 || false,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              const { state_id, city_id, ...rest } = values;
              try {
                const response = await axios.put(`/employee/${id}`, {
                  ...rest,
                });
                if (response.status === 200) {
                  fetchSuccess("Employee updated successfully");
                  navigate("/employees-list");
                }
              } catch (err) {
                fetchError(err.response.data.error);
              }
            }}
          >
            {({ errors, touched, handleChange, setFieldValue, values }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Name
                      </SoftTypography>
                      <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={!!errors.name && touched.name}
                        helperText={touched.name && errors.name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Phone Number
                      </SoftTypography>
                      <TextField
                        name="phone"
                        value={values.phone}
                        inputProps={{ maxLength: 10 }}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={!!errors.phone && touched.phone}
                        helperText={touched.phone && errors.phone}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Email
                      </SoftTypography>
                      <TextField
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={!!errors.email && touched.email}
                        helperText={touched.email && errors.email}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            name="is_active"
                            checked={values.is_active}
                            onChange={(event) => {
                              setFieldValue("is_active", event.target.checked);
                            }}
                            color="primary"
                          />
                        }
                        label={
                          <SoftTypography
                            component="span"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Status
                          </SoftTypography>
                        }
                      />
                      {touched.is_active && errors.is_active && (
                        <FormHelperText error>
                          {errors.is_active}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        State
                      </SoftTypography>
                      <Autocomplete
                        id="state-autocomplete"
                        options={states || []}
                        getOptionLabel={(option) => option.state_name || ""}
                        value={values.state_id ? initialState : null}
                        onChange={(event, newValue) => {
                          setFieldValue("city_id", "");
                          setFieldValue("region_id", "");
                          setFieldValue(
                            "state_id",
                            newValue ? newValue.state_id : ""
                          );
                          setInitialState({
                            state_id: newValue?.state_id || null,
                            state_name: newValue?.state_name || null,
                          });
                          setSelectedState(newValue ? newValue.state_id : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="state_id"
                            variant="outlined"
                            error={!!errors.state_id && touched.state_id}
                            helperText={touched.state_id && errors.state_id}
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        City
                      </SoftTypography>
                      <Autocomplete
                        id="city-autocomplete"
                        options={cities || []}
                        getOptionLabel={(option) => option.city_name || ""}
                        value={values.city_id ? initialCity : null}
                        onChange={(event, newValue) => {
                          setFieldValue("region_id", "");
                          setFieldValue(
                            "city_id",
                            newValue ? newValue.city_id : ""
                          );
                          setInitialCity({
                            city_id: newValue?.city_id || null,
                            city_name: newValue?.city_name || null,
                          });
                          setSelectedCity(newValue ? newValue.city_id : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="city_id"
                            variant="outlined"
                            error={!!errors.city_id && touched.city_id}
                            helperText={touched.city_id && errors.city_id}
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Area
                      </SoftTypography>
                      <Autocomplete
                        id="region-autocomplete"
                        options={areas || []}
                        getOptionLabel={(option) => option.region_name || ""}
                        value={values.region_id ? initialRegion : null}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            "region_id",
                            newValue ? newValue.region_id : ""
                          );
                          setInitialRegion({
                            region_id: newValue?.region_id || null,
                            region_name: newValue?.region_name || null,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="region_id"
                            variant="outlined"
                            error={!!errors.region_id && touched.region_id}
                            helperText={touched.region_id && errors.region_id}
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Asset Type
                      </SoftTypography>
                      <Autocomplete
                        options={assets || []}
                        getOptionLabel={(option) =>
                          option.asset_type_name || ""
                        }
                        value={
                          values.asset_type_id
                            ? assets.find(
                                (type) =>
                                  type.asset_type_id === values.asset_type_id
                              )
                            : null
                        }
                        onChange={(event, newValue) => {
                          setFieldValue(
                            "asset_type_id",
                            newValue ? newValue.asset_type_id : ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="asset_type_id"
                            variant="outlined"
                            error={
                              errors.asset_type_id && touched.asset_type_id
                            }
                            helperText={
                              touched.asset_type_id && errors.asset_type_id
                            }
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Address
                      </SoftTypography>
                      <TextField
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={!!errors.address && touched.address}
                        helperText={touched.address && errors.address}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Skills
                      </SoftTypography>
                      <TextField
                        name="skill_set"
                        value={values.skill_set}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={!!errors.skill_set && touched.skill_set}
                        helperText={touched.skill_set && errors.skill_set}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <SoftButton
                        className="success-btn"
                        variant="gradient"
                        color="success"
                        type="submit"
                      >
                        Update
                      </SoftButton>
                    </Grid>
                    <Grid item>
                      <SoftButton
                        className="cancel-btn"
                        variant="gradient"
                        color="warning"
                        onClick={() => navigate(`/employees-list`)}
                      >
                        Cancel
                      </SoftButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>
      )}
    </DashboardLayout>
  );
};

export default EditEmployee;
