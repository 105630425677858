import { useEffect, useState } from "react";
import axios from "../../api";
const AllPages = () => {
  const [pagesData, setPagesData] = useState(null);
  const [logo, setLogo] = useState(null);
  const [banner, setBanner] = useState(null);
  const handlePages = async () => {
    try {
      const response = await axios.get("/pages", {
        params: {
          is_active: 1,
        },
      });
      const { data } = response;
      setPagesData(data?.list);
      setLogo(data?.logo);
      setBanner(data?.banner);
    } catch (error) {
      //If there is no domain it opens NotFound Page
      if (error.response.data.error === "Subdomain Not Found") {
        window.location.href = `${process.env.REACT_APP_BASE_DOMAIN}/maintenance/404`;
      }
      console.error("Fetching states failed:", error);
    }
  };
  useEffect(() => {
    handlePages();
  }, []);
  return { pagesData, logo, banner };
};
export default AllPages;
