import React, { useEffect, useState, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Select,
  MenuItem,
  Typography,
  Card,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import axios from "../../api";
import { useSelector } from "react-redux";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";

const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#f5f5f5",
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const EventsOverview = () => {
  const [eventType, setEventType] = useState("Scheduled");
  const [rows, setRows] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const fetchTickets = useCallback(async () => {
    try {
      const response = await axios.get(`/tickets/employee/${user?.userId}`, {
        params: {
          status_id: 2,
        },
      });
      const tickets = response.data?.list || [];

      // Separate tickets based on employee_arrival_date
      const scheduledTickets = tickets.filter(
        (ticket) => ticket.employee_arrival_date
      );
      const unscheduledTickets = tickets.filter(
        (ticket) => !ticket.employee_arrival_date
      );

      setRows(
        eventType === "Scheduled" ? scheduledTickets : unscheduledTickets
      );
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  }, [user?.userId, eventType]);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets, eventType]);

  const columns = [
    { name: "ticket_service_id", label: "Service ID" },
    { name: "customer_name", label: "Customer" },
    { name: "customer_phone", label: "Phone" },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const data = tableMeta.tableData[tableMeta.rowIndex];
          const address = `${value}, ${data.city_name}, ${data.state_name}`;
          return (
            <Tooltip title={address}>
              <span>
                {address.length > 20
                  ? `${address.substring(0, 20)}...`
                  : address}
              </span>
            </Tooltip>
          );
        },
      },
    },
    { name: "description", label: "Description" },
    { name: "category_name", label: "Category" },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const data = tableMeta.tableData[tableMeta.rowIndex];
          return (
            <IconButton
              aria-label="view"
              onClick={() =>
                navigate(`/employee/ticket-view/${data.ticket_id}`)
              }
            >
              <Visibility />
            </IconButton>
          );
        },
      },
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <Typography variant="h4" gutterBottom>
          Events Overview
        </Typography>

        <Select
          value={eventType}
          onChange={(e) => setEventType(e.target.value)}
          style={{ marginBottom: "20px", width: "200px" }}
        >
          <MenuItem value="Scheduled">Scheduled</MenuItem>
          <MenuItem value="Unscheduled">Unscheduled</MenuItem>
        </Select>

        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={`${eventType} Tickets`}
            data={rows}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </Card>
    </DashboardLayout>
  );
};

export default EventsOverview;
