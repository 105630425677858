import * as React from "react";
import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmployeeEditTicket from "./EmployeeEditTicket";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import StatusHistory from "examples/ReuseFunctions/StatusHistory";
import { Field, Form, Formik } from "formik";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import StatusTracker from "examples/ReuseFunctions/StatusTracker";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });
const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666",
  },
};
const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const EmployeeTicketList = ({ onClose }) => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status_id");
  const [statusFilter, setStatusFilter] = React.useState(null);
  const [ticketStatuses, setTicketStatuses] = React.useState(null);
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [serviceReason, setServiceReason] = useState(null);
  const [customReason, setCustomReason] = useState("");

  const { fetchError, fetchSuccess } = useSnackbar();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  // const EmployeeRole = localStorage.getItem("service_role") || "Employees";
  const fetchData = React.useCallback(
    async (statusId) => {
      try {
        const endpoint =
          statusId === "1" ? `/tickets` : `/tickets/employee/${user?.userId}`;

        const response = await axios.get(endpoint, {
          params: { status_id: statusFilter ? statusFilter : status },
        });
        const { data } = response;
        setRows(data?.list);
      } catch (error) {
        setRows([]);
      }
    },
    [user?.userId, statusFilter, status]
  );

  React.useEffect(() => {
    // Get status_id from URL query parameters and fetch tickets
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status_id");
    fetchData(status);
  }, [location, fetchData]);
  const fetchStatus = async () => {
    try {
      const [statusResponse] = await Promise.all([
        axios.get("/ticket-statuses"),
      ]);
      setTicketStatuses(statusResponse?.data);
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  React.useEffect(() => {
    fetchStatus();
  }, []);
  const handleStatus = async (ticket) => {
    const statusdata = StatusHistory(
      ticket?.status_history,
      user?.name,
      ticket?.status_name
    );
    const ticketData = {
      status_id: 3,
      status_history: statusdata,
    };
    const trackerData = StatusTracker(
      ticket?.status_tracker,
      "Work started",
      "In-Progress",
      3,
      user?.name,
      ticket?.employee_name,
      ticket?.employee_phone
    );
    ticketData.status_tracker = trackerData;
    try {
      await axios.put(`/tickets/${ticket?.ticket_id}`, { ticketData });
      fetchData();
      fetchSuccess("Ticket updated to in-progress");
    } catch (err) {
      fetchError("Unable to Update Ticket");
    }
  };
  const handleServiceUpdate = async (selectedTicket) => {
    const reason =
      serviceReason === "Other" && customReason
        ? customReason
        : serviceReason || "Service Update from Employee";

    const trackerData = StatusTracker(
      selectedTicket?.status_tracker,
      reason,
      "In Progress",
      3,
      user?.name,
      selectedTicket?.employee_name,
      selectedTicket?.employee_phone || ""
    );

    const ticketData = {
      status_tracker: trackerData,
      status_id: 3,
    };

    try {
      const response = await axios.put(`/tickets/${selectedTicket.ticket_id}`, {
        ticketData,
      });

      // Reset the form and fetch data after update
      fetchData();
      handleServiceClose();
      setServiceReason(null);
      setCustomReason(""); // Reset custom reason
      fetchSuccess(response?.data?.message);
    } catch (err) {
      fetchError(err.response?.data?.error);
    }
  };

  const handleAssignMeTicket = async (ticket) => {
    const ticketData = {
      assigned_employee_id: user?.userId,
      status_id: 2,
      priority_rank: "High",
    };

    const trackerData = StatusTracker(
      ticket?.status_tracker,
      "Engineer is Assigned",
      "In-Progress",
      2,
      user?.name,
      user?.name,
      user?.phone || ""
    );
    ticketData.status_tracker = trackerData;
    ticketData.employee_arrival_date = null;
    try {
      await axios.put(`/tickets/${ticket.ticket_id}`, {
        ticketData,
      });
      fetchData();
      fetchSuccess("Ticket assigned successfully");
      navigate("/dashboard");
    } catch (err) {
      fetchError(err.response.data.error);
    }
  };
  const columns = [
    { name: "category_name", label: "Category" },
    { name: "customer_name", label: "Customer" },
    { name: "customer_phone", label: "Phone" },
    {
      name: "priority_rank",
      label: "Priority",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <span
              className={value ? "active-btn" : ""}
              style={{
                borderColor:
                  value === "High"
                    ? "#00bdaa"
                    : value === "Medium"
                    ? "#ffaa00"
                    : value === "Low"
                    ? "#fe346e"
                    : null,
              }}
            >
              {value ? value : "-"}
            </span>
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        customBodyRender: (value) => {
          const displayValue =
            value?.length > 20 ? `${value.substring(0, 15)}...` : value;
          return (
            <Tooltip title={value}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const city = data?.city_name;
          const state = data?.state_name;
          const addressType = data?.address_type;
          const addressData = `${value}, ${city}, ${state},${addressType}`;

          const displayValue =
            addressData?.length > 20
              ? `${addressData.substring(0, 20)}...`
              : addressData;
          return (
            <Tooltip title={addressData}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    { name: "status_name", label: "Status" },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const ticketData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];

          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* "Assign to Me" button if status is Open */}
              {ticketData?.status_id === 1 ? (
                <Button
                  sx={{
                    position: "relative",
                  }}
                  size="small"
                  variant="contained"
                  onClick={() => handleAssignMeTicket(ticketData)}
                >
                  Assign to Me
                </Button>
              ) : (
                <>
                  <IconButton
                    aria-label="view"
                    onClick={() =>
                      navigate(`/employee/ticket-view/${ticketData.ticket_id}`)
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>

                  {[3].includes(ticketData?.status_id) && (
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleOpen(ticketData)}
                    >
                      <EditIcon />
                    </IconButton>
                  )}

                  {ticketData?.status_id === 2 &&
                    (!ticketData?.employee_arrival_date ? (
                      <Button
                        sx={{
                          position: "relative",
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                          handleModalOpen(ticketData);
                        }}
                      >
                        Arrival Date
                      </Button>
                    ) : (
                      <>
                        <Button
                          sx={{
                            position: "relative",
                            marginRight: 1,
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => {
                            handleModalOpen(ticketData);
                          }}
                        >
                          Arrival Date
                        </Button>
                        <Button
                          sx={{
                            position: "relative",
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => {
                            handleStatus(ticketData);
                          }}
                        >
                          Start
                        </Button>
                      </>
                    ))}

                  {ticketData?.status_id === 3 && (
                    <Button
                      sx={{
                        position: "relative",
                      }}
                      size="small"
                      variant="contained"
                      onClick={() => handleServiceOpen(ticketData)}
                    >
                      Service Update
                    </Button>
                  )}
                </>
              )}
            </Box>
          );
        },
      },
    },
  ];

  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isServiceOpen, setIsServiceOpen] = React.useState(false);
  const [selectedTicket, setSelectedTicket] = React.useState(null);

  const handleOpen = (ticketData) => {
    setSelectedTicket(ticketData);
    setIsEditOpen(true);
  };

  const handleClose = () => {
    setIsEditOpen(false);
  };
  const handleModalOpen = (ticketData) => {
    setSelectedTicket(ticketData);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleServiceOpen = (ticketData) => {
    setSelectedTicket(ticketData);
    setIsServiceOpen(true);
  };

  const handleServiceClose = () => {
    setIsServiceOpen(false);
  };
  const serviceReasons = [
    "Power Supply Issues",
    " Electrical Components Failure",
    "Overheating",
    "Loose or Damaged Wiring",
    "Software/Firmware Issues",
    "Physical Damage",
    "Remote Control or Interface Issues",
    "Voltage Fluctuations",
    "Spare Parts Replacement",
  ];
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div
          style={{
            display: "block",
          }}
        >
          <Grid>
            <FormControl sx={{ paddingLeft: "10px", marginBottom: "2px" }}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                sx={{ paddingLeft: "10px" }}
              >
                Status
              </SoftTypography>
              <Autocomplete
                options={ticketStatuses || []}
                getOptionLabel={(option) => option.status_name}
                onChange={(event, value) => {
                  // fetchData(value?.status_id || null);
                  setStatusFilter(value?.status_id || null);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </FormControl>
          </Grid>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-tickets"
              title={"Manage Tickets"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </SoftBox>
      </Card>
      {isEditOpen && (
        <Modal open={isEditOpen} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
              maxWidth: 500,
              maxHeight: "calc(100vh - 100px)",
              overflow: "auto",
              ...customScrollbarStyle,
            }}
          >
            <EmployeeEditTicket
              employeeTicketData={selectedTicket}
              onClose={handleClose}
              fetchData={fetchData}
            />
          </Box>
        </Modal>
      )}
      {isServiceOpen && (
        <Modal open={isServiceOpen} onClose={handleServiceClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
              maxWidth: 500,
              maxHeight: "calc(100vh - 100px)",
              overflow: "auto",
              ...customScrollbarStyle,
            }}
          >
            <Grid item xs={12}>
              <Box>
                <Typography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Update Service
                </Typography>
                <FormControl fullWidth variant="outlined">
                  <Autocomplete
                    id="service-reason"
                    options={[...serviceReasons, "Other"]}
                    value={serviceReason}
                    onChange={(event, newValue) => {
                      if (newValue === "Other") {
                        setServiceReason("Other");
                      } else {
                        setServiceReason(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{ fontSize: "12px" }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <ArrowDropDownIcon sx={{ marginLeft: "10px" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    freeSolo
                  />
                </FormControl>
                {serviceReason === "Other" && (
                  <Grid item xs={12} sx={{ marginTop: "20px" }}>
                    <Box>
                      <TextField
                        variant="outlined"
                        label="Other Reason"
                        onChange={(event) =>
                          setCustomReason(event.target.value)
                        }
                        value={customReason}
                        fullWidth
                        sx={{
                          "& .MuiInputLabel-root": { fontSize: "12px" },
                          "& .MuiInputBase-input": { fontSize: "12px" },
                        }}
                      />
                    </Box>
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                {serviceReason && (
                  <Grid item>
                    <SoftButton
                      className="success-btn"
                      variant="gradient"
                      color="success"
                      type="submit"
                      onClick={() => handleServiceUpdate(selectedTicket)}
                    >
                      Save
                    </SoftButton>
                  </Grid>
                )}
                <Grid item>
                  <SoftButton
                    className="cancel-btn"
                    variant="gradient"
                    color="warning"
                    onClick={handleServiceClose}
                  >
                    Cancel
                  </SoftButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
      {isModalOpen && (
        <Modal open={isModalOpen} onClose={handleModalClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
              maxWidth: 500,
              maxHeight: "calc(100vh - 100px)",
              overflow: "auto",
              ...customScrollbarStyle,
            }}
          >
            <Formik
              initialValues={{
                employee_arrival_date: "",
                arrival_time: "",
                reason_for_delay: "",
              }}
              onSubmit={async (values) => {
                const ticketData = {};
                const trackerData = StatusTracker(
                  selectedTicket?.status_tracker,
                  values.reason_for_delay
                    ? `Engineer will arrive on ${values.employee_arrival_date} at ${values.arrival_time} due to ${values.reason_for_delay}`
                    : `Engineer will arrive on ${values.employee_arrival_date} at ${values.arrival_time}`,
                  "Todo",
                  3,
                  user?.name,
                  selectedTicket?.employee_name,
                  selectedTicket?.employee_phone
                );

                ticketData.status_tracker = trackerData;
                ticketData.employee_arrival_date = `${values.employee_arrival_date}T${values.arrival_time}`;
                try {
                  await axios.put(`/tickets/${selectedTicket?.ticket_id}`, {
                    ticketData,
                  });
                  fetchData();
                  handleModalClose();
                  fetchSuccess("Arrival date updated successfully");
                } catch (err) {
                  fetchError("Unable to Update Ticket");
                }
              }}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Arrival Date
                        </SoftTypography>
                        <Field
                          className="date-field"
                          name="employee_arrival_date"
                          as={TextField}
                          InputProps={{
                            inputProps: {
                              min: formattedToday,
                            },
                          }}
                          type="date"
                          fullWidth
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Arrival Time
                        </SoftTypography>
                        <Field
                          className="time-field"
                          name="arrival_time"
                          as={TextField}
                          type="time"
                          fullWidth
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    {selectedTicket?.employee_arrival_date && (
                      <Grid item xs={12}>
                        <SoftBox>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Reason For Delay
                          </SoftTypography>
                          <TextField
                            name="reason_for_delay"
                            variant="outlined"
                            onChange={(event) => {
                              const data = event.target.value;
                              setFieldValue(
                                "reason_for_delay",
                                data ? data : null
                              );
                            }}
                            fullWidth
                            error={
                              !!errors.reason_for_delay &&
                              touched.reason_for_delay
                            }
                            helperText={
                              touched.reason_for_delay &&
                              errors.reason_for_delay
                            }
                          />
                        </SoftBox>
                      </Grid>
                    )}
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <SoftButton
                            className="success-btn"
                            variant="gradient"
                            color="success"
                            type="submit"
                          >
                            Save
                          </SoftButton>
                        </Grid>
                        <Grid item>
                          <SoftButton
                            className="cancel-btn"
                            variant="gradient"
                            color="warning"
                            onClick={handleModalClose}
                          >
                            Cancel
                          </SoftButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      )}
    </DashboardLayout>
  );
};

export default EmployeeTicketList;
