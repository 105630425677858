import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Card,
  Box,
  Autocomplete,
  IconButton,
} from "@mui/material";
import axios from "../../api";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import { useDropzone } from "react-dropzone";
import AWS from "aws-sdk";
import AssigneeHistory from "examples/ReuseFunctions/AssigneeHistory";
import StatusHistory from "examples/ReuseFunctions/StatusHistory";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import StatusTracker from "examples/ReuseFunctions/StatusTracker";

const validationSchema = Yup.object({
  // category_id: Yup.string().required("Category is required"),
  asset_type_id: Yup.string().required("Asset Type is required"),
  asset_id: Yup.string().required("Asset  is required"),

  // status_id: Yup.string().required("Status is required"),
  customer_id: Yup.string().required("Customer is required"),
  description: Yup.string().required("Description is required"),
  address_id: Yup.string().required("Address is required"),
  // assigned_employee_id: Yup.string().required("Assignee is required"),
});
const conditionValidationSchema = Yup.object({
  // category_id: Yup.string().required("Category is required"),
  // lift_id: Yup.string().required("Lift is required"),
  // status_id: Yup.string().required("Status is required"),
  asset_type_id: Yup.string().required("Asset Type is required"),
  asset_id: Yup.string().required("Asset  is required"),

  customer_id: Yup.string().required("Customer is required"),
  description: Yup.string().required("Description is required"),
  address_id: Yup.string().required("Address is required"),
  priority_rank: Yup.string().required("Priority rank is required"),
});

const initialValues = {
  // category_id: "",
  customer_id: "",
  assigned_employee_id: null,
  priority_rank: null,
  description: "",
  status_id: "",
  address_id: "",
  file_name: "",
  file_type: "",
  // lift_id: "",
  asset_type_id: "",
  asset_id: "",
};

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});
const AdminAddTicket = () => {
  const user = useSelector((state) => state.auth.user);
  const [employees, setEmployees] = useState(null);
  const [categories, setCategories] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedAssetTypeId, setSelectedAssetTypeId] = useState(null);
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({
    status_id: 1,
    status_name: "Open",
  });
  const { fetchError, fetchSuccess } = useSnackbar();

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerList = await axios.get("/customer", {
          params: {
            is_active: 1,
          },
        });
        setCustomers(customerList.data.list);
      } catch (err) {
        console.error("Error fetching data", err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get(
          `/category/asset-type/${selectedAssetTypeId}`,
          {
            params: {
              is_active: 1,
            },
          }
        );
        setCategories(categoriesResponse.data.list);
      } catch (err) {
        console.error("Error fetching data", err);
      }
    };
    fetchData();
  }, [selectedAssetTypeId]);

  useEffect(() => {
    const fetchEmployeesData = async () => {
      // if (selectedAddress?.region_id && selectedAddress?.asset_type_id) {
      try {
        const employeeslist = await axios.get(
          `/employee/${selectedAddress?.region_id}/${selectedAddress?.asset_type_id}`,
          {
            params: {
              role_id: 4,
            },
          }
        );
        const { data } = employeeslist;
        setEmployees(data.list);
      } catch (err) {
        console.error("Error fetching employees data:", err);
        // setEmployees([]);
      }
      // }
    };

    fetchEmployeesData();
  }, [selectedAddress?.region_id, selectedAddress?.asset_type_id]);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const dropzone = useDropzone({
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "video/mp4": [".mp4"],
    },
    onDrop: (acceptedFiles) => {
      // Append new files to the existing uploadedFiles array
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });
  const handleDelete = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const [assetsData, setAssetsData] = React.useState(null);

  const fetchAssets = useCallback(async () => {
    try {
      const response = await axios.get("/asset-types", {
        params: {
          is_active: 1,
        },
      });
      const assetData =
        response.data.list && Array.isArray(response.data.list)
          ? response.data.list
          : [];

      setAssetsData(assetData);
    } catch (error) {
      console.error(error.response?.data?.error);
    }
  }, []);

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  const [assets, setAssets] = useState([]);
  const fetchData = React.useCallback(async () => {
    try {
      const response = await axios.get(
        `/assets/${selectedAssetTypeId}/${selectedCustomerId}`
      );
      setAssets(response?.data?.list);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  }, [selectedAssetTypeId, selectedCustomerId]);
  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const priorityRanks = ["High", "Medium", "Low"];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{
          padding: "30px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
          Add New Ticket
        </SoftTypography>
        <Formik
          initialValues={initialValues}
          validationSchema={
            selectedEmployee ? conditionValidationSchema : validationSchema
          }
          onSubmit={async (values) => {
            setSubmitting(true);
            const ticketData = {
              category_id: values?.category_id,
              description: values?.description,
              address_id: values?.address_id,
              customer_id: values?.customer_id,
              asset_type_id: values?.asset_type_id,

              asset_id: values?.asset_id,

              priority_rank: values?.priority_rank,
              status_id: values?.assigned_employee_id ? 2 : 1,
              assigned_employee_id: values?.assigned_employee_id,
            };

            if (selectedEmployee?.name) {
              const assignData = AssigneeHistory(null, selectedEmployee?.name);
              ticketData.assignee_history = assignData;
            }
            if (selectedStatus) {
              const statusdata = StatusHistory(
                null,
                user?.name,
                selectedStatus?.status_name
              );
              const trackerData = StatusTracker(
                null,
                "Ticket is Created",
                selectedStatus?.status_name,
                values?.assigned_employee_id ? 2 : 1,
                user?.name,
                selectedEmployee?.name,
                selectedEmployee?.phone
              );
              ticketData.status_history = statusdata;
              ticketData.status_tracker = trackerData;
            }

            let mediaDataArray = [];

            try {
              if (uploadedFiles && uploadedFiles.length > 0) {
                mediaDataArray = await Promise.all(
                  uploadedFiles.map(async (file) => {
                    const fileName = `${Date.now()}-${file.name}`;

                    const params = {
                      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                      Key: fileName,
                      Body: file,
                      ContentType: file.type,
                    };

                    let type = file.type.split("/")[0];
                    if (type === "image") {
                      type = "Photo";
                    }

                    const mediaData = {
                      file_name: fileName,
                      file_type: type,
                      file_path: fileName,
                    };

                    return new Promise((resolve, reject) => {
                      s3.upload(params, (err, data) => {
                        if (err) {
                          console.error("Error uploading file:", err);
                          reject(err);
                        } else {
                          resolve(mediaData);
                        }
                      });
                    });
                  })
                );
                try {
                  const response = await axios.post("/tickets", {
                    ticketData,
                    mediaData: mediaDataArray,
                  });
                  navigate(`/admin/tickets-list`);
                  fetchSuccess(response.data.message);
                } catch (error) {
                  fetchError(error.response.data.error);
                } finally {
                  setSubmitting(false);
                }
              } else {
                try {
                  const response = await axios.post("/tickets", {
                    ticketData,
                  });
                  navigate(`/admin/tickets-list`);
                  fetchSuccess(response.data.message);
                } catch (error) {
                  fetchError(error.response.data.error);
                } finally {
                  setSubmitting(false);
                }
              }
            } catch (error) {
              fetchError(error.response.data.error);
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form
              className="new-ticket-form"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Customer
                    </SoftTypography>
                    <Autocomplete
                      options={customers || []}
                      getOptionLabel={(option) =>
                        `${option.name} - (${option.phone})`
                      }
                      onChange={(event, value) => {
                        setSelectedAddress(null);
                        setFieldValue("assigned_employee_id", null);
                        setFieldValue("address_id", null);
                        setSelectedEmployee(null);
                        setFieldValue("customer_id", value?.customer_id || "");
                        setSelectedAssetType(null);
                        setSelectedAssetTypeId(null);
                        setFieldValue("asset_type_id", "");
                        setFieldValue("asset_id", "");
                        setSelectedAsset(null);
                        setSelectedCategory(null);
                        setFieldValue("category_id", "");
                        setSelectedCustomerId(value?.customer_id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.customer_id && touched.customer_id}
                          helperText={touched.customer_id && errors.customer_id}
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Asset Type
                  </SoftTypography>
                  <Autocomplete
                    options={assetsData || []}
                    getOptionLabel={(option) => `${option.asset_type_name}`}
                    value={selectedAssetType}
                    onChange={(event, value) => {
                      setFieldValue(
                        "asset_type_id",
                        value?.asset_type_id || ""
                      );

                      setFieldValue("category_id", "");
                      setSelectedAssetType(value);
                      setSelectedAssetTypeId(value?.asset_type_id);
                      setSelectedCategory(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={errors.asset_type_id && touched.asset_type_id}
                        helperText={
                          touched.asset_type_id && errors.asset_type_id
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Category
                    </SoftTypography>
                    <Autocomplete
                      options={categories || []}
                      getOptionLabel={(option) => option.name}
                      value={selectedCategory}
                      onChange={(event, value) => {
                        setFieldValue("category_id", value?.category_id || "");
                        setSelectedCategory(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.category_id && touched.category_id}
                          helperText={touched.category_id && errors.category_id}
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Assets
                    </SoftTypography>
                    <Autocomplete
                      options={assets || []}
                      getOptionLabel={(option) => option.asset_name || ""}
                      value={selectedAsset}
                      onChange={(event, value) => {
                        setSelectedAddress(value);
                        setSelectedAsset(value);
                        setFieldValue("asset_id", value ? value.asset_id : "");
                        setFieldValue(
                          "address_id",
                          value ? value.address_id : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={errors.asset_id && touched.asset_id}
                          helperText={touched.asset_id && errors.asset_id}
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Assignee
                    </SoftTypography>
                    <Autocomplete
                      options={employees || []}
                      getOptionLabel={(option) => option.name}
                      value={selectedEmployee}
                      onChange={(event, value) => {
                        setFieldValue(
                          "assigned_employee_id",
                          value?.employee_id || null
                        );
                        setSelectedStatus(
                          value?.employee_id
                            ? { status_id: 2, status_name: "ToDo" }
                            : { status_id: 1, status_name: "Open" }
                        );
                        setSelectedEmployee(value);
                        if (!value) {
                          setSelectedEmployee(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={
                            !!errors.assigned_employee_id &&
                            touched.assigned_employee_id
                          }
                          helperText={
                            touched.assigned_employee_id &&
                            errors.assigned_employee_id
                          }
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>

                {values?.assigned_employee_id ? (
                  <Grid item xs={12} sm={6}>
                    <SoftBox>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Priority Rank
                      </SoftTypography>
                      <Autocomplete
                        options={priorityRanks || []}
                        getOptionLabel={(option) => option}
                        value={selectedPriority}
                        onChange={(event, value) => {
                          setFieldValue("priority_rank", value || null);
                          setSelectedPriority(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={
                              errors.priority_rank && touched.priority_rank
                            }
                            helperText={
                              touched.priority_rank && errors.priority_rank
                            }
                          />
                        )}
                      />
                    </SoftBox>
                  </Grid>
                ) : null}
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address
                    </SoftTypography>
                    <TextField
                      name="address_id"
                      fullWidth
                      value={
                        selectedAddress
                          ? `${selectedAddress?.address_type}-${selectedAddress?.city_name}-${selectedAddress?.region_name}`
                          : ""
                      }
                      variant="outlined"
                      disabled
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Description
                    </SoftTypography>
                    <Field
                      name="description"
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      error={!!errors.description && touched.description}
                      helperText={touched.description && errors.description}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} md={12}>
                  {uploadedFiles.length > 0 && (
                    <div className="upload-media">
                      {uploadedFiles.map((file, index) => (
                        <div
                          key={index}
                          className="media-container"
                          style={{ position: "relative" }}
                        >
                          {file.preview &&
                            (file.type.startsWith("image") ? (
                              <img
                                src={file.preview}
                                alt={`media-${index}`}
                                className="upload-img"
                              />
                            ) : (
                              <video
                                className="upload-video"
                                controls
                                width="100%"
                                height="140px"
                              >
                                <source src={file.preview} type="video/mp4" />
                              </video>
                            ))}
                          <IconButton
                            onClick={() => handleDelete(index)}
                            color="secondary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                  )}
                  <Box
                    sx={{ cursor: "pointer", width: "fit-content" }}
                    {...dropzone.getRootProps()}
                  >
                    <Box>
                      <label htmlFor="image-upload-input">
                        <input
                          name="file_name"
                          {...dropzone.getInputProps({
                            name: "file_name",
                          })}
                        />
                        <Button
                          component="span"
                          variant="contained"
                          color="primary"
                          className="upload-btn"
                        >
                          Upload File
                        </Button>
                      </label>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="success-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    variant="contained"
                    type="submit"
                    disabled={submitting}
                    color="success"
                  >
                    Save
                  </Button>

                  <Button
                    className="cancel-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                      ml: 2.5,
                    }}
                    variant="contained"
                    color="warning"
                    onClick={() => navigate(`/admin/tickets-list`)}
                    type="cancel"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>
    </DashboardLayout>
  );
};

export default AdminAddTicket;
