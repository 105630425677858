import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "../../api";
import { useSelector } from "react-redux";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
const localizer = momentLocalizer(moment);

const EventsCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTodoTickets = async () => {
      try {
        const response = await axios.get(`/tickets/employee/${user?.userId}`);
        const tickets = Array.isArray(response.data)
          ? response.data
          : response.data.list || [];
        const eventsData = tickets.map((ticket) => ({
          title: `Ticket ${ticket.ticket_service_id}`,
          start: new Date(ticket.employee_arrival_date),
          end: new Date(ticket.employee_arrival_date),
          ticketId: ticket.ticket_service_id,
          ticketid: ticket.ticket_id,
          Customer: ticket.customer_name,
          Phone: ticket.customer_phone,
          State: ticket.state_name,
          City: ticket.city_name,
          Region: ticket.region_name,
          address: ticket.address,
          description: ticket.description,
          category: ticket.category_name,
          latitude: ticket.latitude,
          longitude: ticket.longitude,
        }));

        setEvents(eventsData);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    fetchTodoTickets();
  }, [user?.userId]);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  const eventStyleGetter = (event) => {
    const eventDate = moment(event.start);
    const currentDate = moment();
    let backgroundColor = eventDate.isSameOrAfter(currentDate, "day")
      ? "#00bdaa"
      : "#FE346E";

    return {
      style: {
        backgroundColor,
        color: "white",
        borderRadius: "4px",
        padding: "4px",
        border: "none",
      },
    };
  };

  const handleViewTicket = () => {
    if (selectedEvent) {
      navigate(`/employee/ticket-view/${selectedEvent.ticketid}`);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "1200px",
            padding: "40px",
          }}
        >
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 700, width: "100%" }}
            selectable
            onSelectEvent={handleEventClick}
            className="custom-calendar"
            eventPropGetter={eventStyleGetter}
          />

          <Modal open={!!selectedEvent} onClose={closeModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                maxWidth: "500px",
                width: "90%",
              }}
            >
              {selectedEvent && (
                <>
                  <IconButton
                    onClick={closeModal}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Ticket Details
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      marginTop: "16px",
                    }}
                  >
                    {[
                      { label: "Ticket ID", value: selectedEvent.ticketId },
                      { label: "Customer", value: selectedEvent.Customer },
                      { label: "Phone", value: selectedEvent.Phone },
                      { label: "State", value: selectedEvent.State },
                      { label: "City", value: selectedEvent.City },
                      { label: "Region", value: selectedEvent.Region },
                      { label: "Address", value: selectedEvent.address },
                      {
                        label: "Description",
                        value: selectedEvent.description,
                      },
                      { label: "Category", value: selectedEvent.category },
                    ].map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "0.875rem",
                            fontWeight: "bold",
                            flex: 1,
                            textAlign: "right",
                          }}
                        >
                          {item.label}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "0.875rem", margin: "0 8px" }}
                        >
                          :
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "0.875rem",
                            flex: 2,
                            textAlign: "left",
                          }}
                        >
                          {item.value}
                        </Typography>
                      </div>
                    ))}
                  </div>

                  {selectedEvent?.latitude && selectedEvent?.longitude && (
                    <Link
                      href={`https://www.google.com/maps/search/?api=1&query=${selectedEvent?.latitude},${selectedEvent?.longitude}`}
                      target="_blank"
                      rel="noopener"
                      sx={{
                        display: "block",
                        textAlign: "center",
                        marginTop: "16px",
                        color: "primary.main",
                        textDecoration: "none",
                        fontSize: "14px",
                      }}
                    >
                      View Location on Google Maps
                    </Link>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<VisibilityIcon />}
                    onClick={handleViewTicket}
                    sx={{
                      mt: 3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    View Ticket
                  </Button>
                </>
              )}
            </Box>
          </Modal>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EventsCalendar;
