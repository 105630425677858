import React, { useCallback, useState } from "react";
import axios from "../../api";
import {
  Card,
  TextField,
  FormControl,
  Grid,
  Autocomplete,
  InputAdornment,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import Locations from "examples/ReuseFunctions/Locations";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import AWS from "aws-sdk";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .typeError("Enter a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Enter a valid phone number")
    .required("Phone number is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum.")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
      "Password must contain at least one letter, one number, and one special character"
    ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  state_id: Yup.string().required("State is required"),
  city_id: Yup.string().required("City is required"),
  region_id: Yup.string().required("Area is required"),
  address_type: Yup.string().required("Address type is required"),
  address: Yup.string().required("Address is required"),
});

const AddCustomer = () => {
  const navigate = useNavigate();
  const { states, cities, areas, setSelectedState, setSelectedCity } =
    Locations();
  const { fetchError, fetchSuccess } = useSnackbar();
  // const user = useSelector((state) => state.auth.user);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [markerPosition, setMarkerPosition] = useState({
    lat: 17.385,
    lng: 78.4867,
  });

  const generatePassword = () => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*?";
    let password = "P1!";
    for (let i = 0; i < 9; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const dropzone = useDropzone({
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "video/mp4": [".mp4"],
    },
    onDrop: (acceptedFiles) => {
      // Append new files to the existing uploadedFiles array
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  React.useEffect(() => {
    setUploadedFiles(dropzone.acceptedFiles);
  }, [dropzone.acceptedFiles]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA4G8LGyP5VQtJGxZzjyYAWnZTeMWELqaA",
  });

  const handleMapClick = useCallback((event) => {
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  }, []);
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{
          padding: "30px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
          Add New Customer
        </SoftTypography>
        <Formik
          // enableReinitialize
          initialValues={{
            name: "",
            email: "",
            phone: "",
            password: "",
            state_id: "",
            city_id: "",
            region_id: "",
            address: "",
            address_type: "",
            confirm_password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setSubmitting(true);
            const customerData = {
              name: values.name,
              email: values.email,
              phone: values.phone,
              password: values.password,
            };
            const addressData = {
              state_id: values.state_id,
              city_id: values.city_id,
              region_id: values.region_id,
              address: values.address,
              address_type: values.address_type,
              is_primary: 1,
              latitude: markerPosition.lat,
              longitude: markerPosition.lng,
            };

            const subscriptionData = {
              subscription_type: values.subscription_type,
              start_date: values.start_date,
              end_date: values.end_date,
            };
            try {
              const response = await axios.post("/customer", {
                customerData,
                subscriptionData,
              });
              const customerId = response.data.id;
              await axios.post("/address", {
                ...addressData,
                customer_id: customerId,
              });

              subscriptionData.customer_id = customerId;
              try {
                if (uploadedFiles && uploadedFiles.length > 0) {
                  const file = uploadedFiles[0];
                  const fileName = `${Date.now()}-${file.name}`;
                  // liftsData.file_name = fileName;
                  const params = {
                    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                    Key: fileName,
                    Body: file,
                    ContentType: file.type,
                  };
                  let type = file.type.split("/")[0];
                  if (type === "image") {
                    type = "Photo";
                  }
                  // liftsData.file_type = type;
                  s3.upload(params, async (err, data) => {
                    if (err) {
                      console.error("Error uploading file:", err);
                      setSubmitting(false);
                    }
                  });
                }
              } catch (error) {
                fetchError(error.response.data.error);
                setSubmitting(false);
              }
              fetchSuccess("Customer created successfully");
              navigate("/customers-list");
              setSubmitting(false);
            } catch (err) {
              fetchError(err.response.data.error);
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, handleChange, setFieldValue, values }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Name
                    </SoftTypography>
                    <TextField
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={!!errors.name && touched.name}
                      helperText={touched.name && errors.name}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Phone Number
                    </SoftTypography>
                    <TextField
                      name="phone"
                      value={values.phone}
                      inputProps={{ maxLength: 10 }}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={!!errors.phone && touched.phone}
                      helperText={touched.phone && errors.phone}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Email
                    </SoftTypography>
                    <TextField
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={!!errors.email && touched.email}
                      helperText={touched.email && errors.email}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  marginTop="20px"
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      const password = generatePassword();
                      setFieldValue("password", password);
                      setFieldValue("confirm_password", password);
                    }}
                  >
                    Generate Password
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Password
                    </SoftTypography>
                    <TextField
                      className="view-password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={!!errors.password && touched.password}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Confirm Password
                    </SoftTypography>
                    <TextField
                      className="view-password"
                      name="confirm_password"
                      type={showConfirmPassword ? "text" : "password"}
                      value={values.confirm_password}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={
                        !!errors.confirm_password && touched.confirm_password
                      }
                      helperText={
                        touched.confirm_password && errors.confirm_password
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      State
                    </SoftTypography>
                    <Autocomplete
                      id="state-autocomplete"
                      options={states || []}
                      getOptionLabel={(option) => option.state_name || ""}
                      value={
                        states?.find(
                          (state) => state.state_id === values.state_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue("city_id", "");
                        setFieldValue("region_id", "");
                        setFieldValue(
                          "state_id",
                          newValue ? newValue.state_id : ""
                        );
                        setSelectedState(newValue ? newValue.state_id : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="state_id"
                          variant="outlined"
                          error={!!errors.state_id && touched.state_id}
                          helperText={touched.state_id && errors.state_id}
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      City
                    </SoftTypography>
                    <Autocomplete
                      id="city-autocomplete"
                      options={cities || []}
                      getOptionLabel={(option) => option.city_name || ""}
                      value={
                        cities?.find(
                          (city) => city.city_id === values.city_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue("region_id", "");
                        setFieldValue(
                          "city_id",
                          newValue ? newValue.city_id : ""
                        );
                        setSelectedCity(newValue ? newValue.city_id : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="city_id"
                          variant="outlined"
                          error={!!errors.city_id && touched.city_id}
                          helperText={touched.city_id && errors.city_id}
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Area
                    </SoftTypography>
                    <Autocomplete
                      id="region-autocomplete"
                      options={areas || []}
                      getOptionLabel={(option) => option.region_name || ""}
                      value={
                        areas?.find(
                          (city) => city.region_id === values.region_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "region_id",
                          newValue ? newValue.region_id : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="region_id"
                          variant="outlined"
                          error={!!errors.region_id && touched.region_id}
                          helperText={touched.region_id && errors.region_id}
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address Type
                    </SoftTypography>
                    <TextField
                      name="address_type"
                      value={values.address_type}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={!!errors.address_type && touched.address_type}
                      helperText={touched.address_type && errors.address_type}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address
                    </SoftTypography>
                    <TextField
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={!!errors.address && touched.address}
                      helperText={touched.address && errors.address}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Latitude
                    </SoftTypography>
                    <TextField
                      name="latitude"
                      value={markerPosition.lat}
                      onChange={(e) =>
                        setMarkerPosition((prev) => ({
                          ...prev,
                          lat: parseFloat(e.target.value),
                        }))
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Longitude
                    </SoftTypography>
                    <TextField
                      name="longitude"
                      value={markerPosition.lng}
                      onChange={(e) =>
                        setMarkerPosition((prev) => ({
                          ...prev,
                          lng: parseFloat(e.target.value),
                        }))
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>

                {/* Map Grid Item */}
                <Grid item xs={12} style={{ height: "400px" }}>
                  <GoogleMap
                    center={markerPosition}
                    zoom={10}
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    onClick={handleMapClick}
                  >
                    <Marker position={markerPosition} />
                  </GoogleMap>
                </Grid>
                <Grid item xs={12} md={6}>
                  {uploadedFiles.length > 0 && (
                    <div className="upload-media">
                      {uploadedFiles[0]?.preview && (
                        <div
                          className="media-container"
                          style={{ position: "relative" }}
                        >
                          {uploadedFiles[0]?.type.startsWith("image") ? (
                            <img
                              src={uploadedFiles[0]?.preview}
                              alt="media"
                              className="upload-img"
                            />
                          ) : (
                            <video
                              className="upload-video"
                              controls
                              width="100%"
                              height="140px"
                            >
                              <source
                                src={uploadedFiles[0]?.preview}
                                type="video/mp4"
                              />
                            </video>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  <Box
                    sx={{ cursor: "pointer", width: "fit-content" }}
                    {...dropzone.getRootProps()}
                  >
                    <Box>
                      <label htmlFor="image-upload-input">
                        <input
                          name="file_name"
                          {...dropzone.getInputProps({
                            name: "file_name",
                          })}
                        />
                        <Button
                          component="span"
                          variant="contained"
                          color="primary"
                          className="upload-btn"
                        >
                          Upload File
                        </Button>
                      </label>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <SoftButton
                      className="success-btn"
                      variant="gradient"
                      color="success"
                      type="submit"
                      disabled={submitting}
                    >
                      Save
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      className="cancel-btn"
                      variant="gradient"
                      color="warning"
                      onClick={() => navigate(`/customers-list`)}
                    >
                      Cancel
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>
    </DashboardLayout>
  );
};

export default AddCustomer;
