import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Grid,
} from "@mui/material";
import { Formik, Form } from "formik";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import axios from "../../api";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import { useSelector } from "react-redux";
import SoftButton from "components/SoftButton";
import * as Yup from "yup";
import StatusHistory from "examples/ReuseFunctions/StatusHistory";
import AssigneeHistory from "examples/ReuseFunctions/AssigneeHistory";
import StatusTracker from "examples/ReuseFunctions/StatusTracker";
// import CommentsHistory from "examples/ReuseFunctions/CommentsHistory";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461", // Lighter black color
    borderRadius: "8px", // Curved border
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666", // Lighter black color on hover
  },
};
const validationSchema = Yup.object({
  assigned_employee_id: Yup.number().required("Assignee is required"),
  // comment: Yup.string().required("Comment is required"),
});
const conditionValidationSchema = Yup.object({
  assigned_employee_id: Yup.number().required("Assignee is required"),
  // comment: Yup.string().required("Comment is required"),
  priority_rank: Yup.string().required("Priority rank is required"),
});
const AssignTicket = ({ isOpen, onClose, fetchData, assignTicketData }) => {
  const { fetchError, fetchSuccess } = useSnackbar();
  const user = useSelector((state) => state.auth.user);

  const [employees, setEmployees] = useState(null);
  // const [commentData, setCommentData] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState({
    employee_id: assignTicketData.assigned_employee_id || "",
    name: assignTicketData.employee_name,
  });
  const [selectedStatus, setSelectedStatus] = useState({
    status_id: assignTicketData.status_id,
    status_name: assignTicketData.status_name,
  });

  useEffect(() => {
    const fetchEmployeesData = async () => {
      try {
        const employeeslist = await axios.get(
          `/employee/${assignTicketData?.region_id}/${assignTicketData?.asset_type_id}`,
          {
            params: {
              role_id: 4,
            },
          }
        );
        const { data } = employeeslist;
        setEmployees(data.list);
      } catch (err) {
        console.error("Error fetching employees data:", err);
      }
    };

    fetchEmployeesData();
  }, [assignTicketData?.region_id, assignTicketData?.asset_type_id]);
  const priorityRanks = ["High", "Medium", "Low"];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="otp-modal"
      aria-describedby="otp-modal-description"
    >
      <Box
        className="emp-ticket-assign"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          minWidth: 500,
          minHeight: 220,
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          ...customScrollbarStyle,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Employee Ticket Assign
        </Typography>
        <Formik
          enableReinitialize
          initialValues={{
            assigned_employee_id: assignTicketData?.assigned_employee_id || "",
            status_id: assignTicketData.status_id || "",
            priority_rank: assignTicketData?.priority_rank || null,
            comment: "",
          }}
          validationSchema={
            selectedEmployee ? conditionValidationSchema : validationSchema
          }
          onSubmit={async (values) => {
            const ticketData = {
              assigned_employee_id: selectedEmployee?.employee_id,
              status_id: selectedEmployee?.employee_id ? 2 : 1,
              priority_rank: values?.priority_rank,
            };

            if (
              assignTicketData?.assigned_employee_id !==
              values?.assigned_employee_id
            ) {
              const assignData = AssigneeHistory(
                assignTicketData?.assignee_history,
                selectedEmployee?.name
              );
              ticketData.assignee_history = assignData;
            }
            if (assignTicketData?.status_id !== values?.status_id) {
              const statusdata = StatusHistory(
                assignTicketData?.status_history,
                user?.name,
                selectedStatus?.status_name
              );
              ticketData.status_history = statusdata;
            }

            // if (commentData) {
            //   const commentId = `conv-${Date.now()}`;
            //   ticketData.comments = CommentsHistory(
            //     assignTicketData?.comments,
            //     commentData,
            //     commentId, // Pass conversation ID
            //     user?.userId, // Participant ID
            //     user?.Role[0],
            //     user?.name
            //   );
            // }
            const trackerData = StatusTracker(
              assignTicketData?.status_tracker,
              "Engineer is Assigned",
              selectedStatus?.status_name,
              values?.assigned_employee_id ? 2 : 1,
              user?.name,
              selectedEmployee?.name,
              selectedEmployee?.phone
            );
            ticketData.status_tracker = trackerData;
            ticketData.employee_arrival_date = null;
            try {
              await axios.put(`/tickets/${assignTicketData.ticket_id}`, {
                ticketData,
              });
              fetchData();
              onClose();
              fetchSuccess("Ticket assigned successfully");
            } catch (err) {
              fetchError(err.response.data.error);
            }
          }}
        >
          {({ isSubmitting, setFieldValue, errors, touched, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Assignee
                    </SoftTypography>
                    <Autocomplete
                      options={employees || []}
                      getOptionLabel={(option) => option.name}
                      value={
                        values?.assigned_employee_id ? selectedEmployee : null
                      }
                      onChange={(event, value) => {
                        setFieldValue(
                          "assigned_employee_id",
                          value?.employee_id || ""
                        );
                        setSelectedStatus(
                          value?.employee_id
                            ? { status_name: "ToDo" }
                            : { status_name: "Open" }
                        );
                        setSelectedEmployee(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={
                            !!errors.assigned_employee_id &&
                            touched.assigned_employee_id
                          }
                          helperText={
                            touched.assigned_employee_id &&
                            errors.assigned_employee_id
                          }
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>
                {values?.assigned_employee_id ? (
                  <Grid item xs={6} sm={6}>
                    <SoftBox>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Priority Rank
                      </SoftTypography>
                      <Autocomplete
                        options={priorityRanks || []}
                        getOptionLabel={(option) => option}
                        value={values.priority_rank}
                        onChange={(event, value) => {
                          setFieldValue("priority_rank", value || null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={
                              errors.priority_rank && touched.priority_rank
                            }
                            helperText={
                              touched.priority_rank && errors.priority_rank
                            }
                          />
                        )}
                      />
                    </SoftBox>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <SoftButton
                      className="success-btn"
                      variant="gradient"
                      color="success"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      className="cancel-btn"
                      variant="gradient"
                      color="warning"
                      onClick={onClose}
                    >
                      Cancel
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AssignTicket;
