import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Card,
  MenuItem,
  Box,
  IconButton,
  Autocomplete,
} from "@mui/material";
import axios from "../../api";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import AWS from "aws-sdk";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import DeleteIcon from "@mui/icons-material/Delete";

const validationSchema = Yup.object({
  category_id: Yup.string().required("Category is required"),
  asset_type_id: Yup.string().required("Asset Type is required"),
  asset_id: Yup.string().required("Asset is required"),
  description: Yup.string().required("Description is required"),
  address_id: Yup.string().required("Address is required"),
});

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const EditTicket = () => {
  const [categories, setCategories] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [assets, setAssets] = useState([]);
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [initialCategory, setInitialCategory] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [ticketData, setTicketData] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const { fetchError, fetchSuccess } = useSnackbar();
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleDelete = (index, type) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  const dropzone = useDropzone({
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "video/mp4": [".mp4"],
    },
    onDrop: (acceptedFiles) => {
      // Append new files to the existing uploadedFiles array
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const fetchData = useCallback(async () => {
    try {
      const [assetTypeResponse, addressesResponse, ticketResponse] =
        await Promise.all([
          axios.get("/asset-types", { params: { is_active: 1 } }),
          axios.get(`/address/customer/${user.userId}`),
          axios.get(`/tickets/${id}`),
        ]);
      setAssetData(assetTypeResponse.data.list);
      setAddresses(addressesResponse.data.list);
      const { data } = ticketResponse;
      const ticketData = data?.list;
      setTicketData(ticketData);
      setInitialValues({
        category_id: ticketData.category_id,
        description: ticketData.description,
        address_id: ticketData.address_id,
        multimedia: ticketData.multimedia,
        asset_type_id: ticketData.asset_type_id,
        asset_id: ticketData.asset_id,
      });

      setSelectedAssetType({
        asset_type_id: ticketData?.asset_type_id,
        asset_type_name: ticketData?.asset_type_name,
      });
      setSelectedAsset({
        asset_id: ticketData.asset_id,
        asset_name: ticketData?.asset_name,
      });
      setInitialCategory({
        category_id: ticketData.category_id || "",
        name: ticketData?.category_name,
      });
    } catch (err) {
      navigate("/customer/tickets-list");
      fetchError(err.response.data.error);
      console.error("Error fetching data", err);
    }
  }, [user, id, fetchError, navigate]);

  const fetchAssetData = useCallback(async () => {
    try {
      const response = await axios.get(
        `/assets/${selectedAssetType?.asset_type_id}/${user?.userId}`
      );
      setAssets(response?.data?.list);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  }, [selectedAssetType, user]);

  useEffect(() => {
    fetchAssetData();
  }, [fetchAssetData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleMediaDelete = async (mediaId) => {
    try {
      const response = await axios.delete(`/tickets/media/${mediaId}`);
      fetchData();
      fetchSuccess(response.data.message);
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get(
          `/category/asset-type/${selectedAssetType.asset_type_id}`,
          {
            params: {
              is_active: 1,
            },
          }
        );
        setCategories(categoriesResponse.data.list);
      } catch (err) {
        console.error("Error fetching data", err);
      }
    };
    fetchData();
  }, [selectedAssetType]);

  if (!initialValues) {
    return <Typography>Loading...</Typography>;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!ticketData.assigned_employee_id && (
        <Card
          style={{
            padding: "30px",
            width: "100%",
            borderRadius: "8px",
          }}
        >
          <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
            Edit Ticket
          </SoftTypography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              setSubmitting(true);
              const ticketData = {
                category_id: values.category_id,
                asset_id: values.asset_id,
                asset_type_id: values.asset_type_id,
                description: values.description,
                address_id: values.address_id,
                customer_id: user?.userId,
              };

              let updatedMediaData = [];

              try {
                if (uploadedFiles && uploadedFiles.length > 0) {
                  updatedMediaData = await Promise.all(
                    uploadedFiles.map(async (file) => {
                      const fileName = `${Date.now()}-${file.name}`;

                      const params = {
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: fileName,
                        Body: file,
                        ContentType: file.type,
                      };

                      let type = file.type.split("/")[0];
                      if (type === "image") {
                        type = "Photo";
                      }

                      const mediaData = {
                        file_name: fileName,
                        file_type: type,
                        file_path: fileName,
                      };

                      return new Promise((resolve, reject) => {
                        s3.upload(params, (err, data) => {
                          if (err) {
                            console.error("Error uploading file:", err);
                            reject(err);
                          } else {
                            resolve(mediaData);
                          }
                        });
                      });
                    })
                  );
                  try {
                    const response = await axios.put(`/tickets/${id}`, {
                      ticketData,
                      mediaData: updatedMediaData,
                    });
                    navigate("/customer/tickets-list");
                    fetchSuccess(response.data.message);
                  } catch (error) {
                    fetchError(error.response.data.error);
                  } finally {
                    setSubmitting(false);
                  }
                } else {
                  try {
                    const response = await axios.put(`/tickets/${id}`, {
                      ticketData,
                    });
                    navigate("/customer/tickets-list");
                    fetchSuccess(response.data.message);
                  } catch (error) {
                    fetchError(error.response.data.error);
                  } finally {
                    setSubmitting(false);
                  }
                }
              } catch (error) {
                fetchError(error.response.data.error);
                setSubmitting(false);
              }
            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form
                className="edit-ticket-form"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SoftBox>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Asset Type
                      </SoftTypography>
                      <Autocomplete
                        options={assetData || []}
                        getOptionLabel={(option) => option.asset_type_name}
                        value={selectedAssetType}
                        onChange={(event, value) => {
                          setFieldValue(
                            "asset_type_id",
                            value?.asset_type_id || ""
                          );
                          setSelectedAssetType(value);
                          setSelectedAsset(null);
                          setInitialCategory(null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={
                              errors.asset_type_id && touched.asset_type_id
                            }
                            helperText={
                              touched.asset_type_id && errors.asset_type_id
                            }
                          />
                        )}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftBox>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Category
                      </SoftTypography>
                      <Autocomplete
                        options={categories || []}
                        getOptionLabel={(option) => option.name}
                        value={initialCategory}
                        onChange={(event, value) => {
                          setFieldValue(
                            "category_id",
                            value?.category_id || ""
                          );
                          setInitialCategory(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={errors.category_id && touched.category_id}
                            helperText={
                              touched.category_id && errors.category_id
                            }
                          />
                        )}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftBox>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Asset
                      </SoftTypography>
                      <Autocomplete
                        options={assets || []}
                        getOptionLabel={(option) => option.asset_name}
                        value={selectedAsset}
                        onChange={(event, value) => {
                          setFieldValue("asset_id", value?.asset_id || "");
                          setSelectedAsset(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={errors.asset_id && touched.asset_id}
                            helperText={touched.asset_id && errors.asset_id}
                          />
                        )}
                      />
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SoftBox>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Description
                      </SoftTypography>
                      <Field
                        name="description"
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        error={!!errors.description && touched.description}
                        helperText={touched.description && errors.description}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftBox>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Address
                      </SoftTypography>
                      <Field
                        name="address_id"
                        as={TextField}
                        select
                        variant="outlined"
                        fullWidth
                        error={!!errors.address_id && touched.address_id}
                        helperText={touched.address_id && errors.address_id}
                      >
                        {addresses.map((address) => (
                          <MenuItem
                            key={address.address_id}
                            value={address.address_id}
                          >
                            {address.address_type}
                          </MenuItem>
                        ))}
                      </Field>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div
                      className={
                        ticketData?.multimedia.length > 0 ||
                        uploadedFiles?.length > 0
                          ? "upload-media"
                          : ""
                      }
                    >
                      {ticketData?.multimedia && (
                        <div>
                          {ticketData.multimedia.map((media, index) => (
                            <div
                              className="media-container"
                              key={index}
                              style={{ position: "relative" }}
                            >
                              {media.file_type === "Photo" ? (
                                <img
                                  className="upload-img"
                                  src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${media.file_name}`}
                                  alt={`media-${index}`}
                                />
                              ) : (
                                <video
                                  className="upload-video"
                                  controls
                                  width="100%"
                                  height="140px"
                                >
                                  <source
                                    src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${media.file_name}`}
                                    type="video/mp4"
                                  />
                                </video>
                              )}
                              <IconButton
                                onClick={() =>
                                  handleMediaDelete(media?.multimedia_id)
                                }
                                style={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                }}
                                color="secondary"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ))}
                        </div>
                      )}
                      {uploadedFiles.map((file, index) => (
                        <div
                          className="media-container"
                          key={index}
                          style={{ position: "relative" }}
                        >
                          {file.preview &&
                            (file.type.charAt(0) === "i" ? (
                              <img src={file.preview} alt={`media-${index}`} />
                            ) : (
                              <video controls width="100%" height="140px">
                                <source src={file.preview} type="video/mp4" />
                              </video>
                            ))}
                          <IconButton
                            onClick={() => handleDelete(index)}
                            style={{ position: "absolute", top: 5, right: 5 }}
                            color="secondary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                    <Box
                      sx={{ cursor: "pointer", width: "fit-content" }}
                      {...dropzone.getRootProps()}
                    >
                      <Box>
                        <label htmlFor="image-upload-input">
                          <input
                            name="file_name"
                            {...dropzone.getInputProps({
                              name: "file_name",
                            })}
                          />
                          <Button
                            className="upload-btn"
                            component="span"
                            variant="contained"
                            color="primary"
                          >
                            Add/Upload File
                          </Button>
                        </label>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="success-btn"
                      sx={{
                        position: "relative",
                        minWidth: 100,
                      }}
                      variant="contained"
                      disabled={submitting}
                      type="submit"
                      color="success"
                    >
                      Save
                    </Button>

                    <Button
                      className="cancel-btn"
                      sx={{
                        position: "relative",
                        minWidth: 100,
                        ml: 2.5,
                      }}
                      variant="contained"
                      color="warning"
                      onClick={() => navigate("/customer/tickets-list")}
                      type="cancel"
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>
      )}
    </DashboardLayout>
  );
};

export default EditTicket;
